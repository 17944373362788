import React, { useState, useEffect } from 'react';
import { size, transform } from "lodash";
import initializeLottie from './Helpers/lottieAPI';
let frameRate, totalFrames, compDurationMilliseconds, compHeight, compWidth, lottieJSON;

const motionTokens = {
  curveAccelerateMax: "cubic-bezier(0.9,0.1,1,0.2)",
  curveAccelerateMid: "cubic-bezier(1,0,1,1)",
  curveAccelerateMin: "cubic-bezier(0.8,0,0.78,1)",
  curveDecelerateMax: "cubic-bezier(0.1,0.9,0.2,1)",
  curveDecelerateMid: "cubic-bezier(0,0,0,1)",
  curveDecelerateMin: "cubic-bezier(0.33,0,0.1,1)",
  curveEasyEase: "cubic-bezier(0.33,0,0.67,1)",
  curveEasyEaseMax: "cubic-bezier(0.8,0,0.2,1)",
  curveLinear: "cubic-bezier(0,0,1,1)",
  durationFast: 150,
  durationFaster: 100,
  durationGentle: 250,
  durationNormal: 200,
  durationSlow: 300,
  durationSlower: 400,
  durationUltraFast: 50,
  durationUltraSlow: 500
};

export default function convertLottieToReact(lottieJSON, lottiePreviewBG) {
  console.log("lottieJSON", lottieJSON)
  frameRate = lottieJSON.fr;
  totalFrames = lottieJSON.op - lottieJSON.ip;
  compHeight = lottieJSON.h
  compWidth = lottieJSON.w
  lottieJSON = lottieJSON
  compDurationMilliseconds = calcDurationToken((totalFrames / frameRate) * 1000);



  let reactCode = {
    imports: `
      import * as React from 'react';
      import { createMotionComponent, makeStyles, type MotionImperativeRef, motionTokens, tokens } from '@fluentui/react-components';
      import './styles.css';`,
    dynamicImports: '',
    styles: '',
    motionComponents: '',
    component: `
      export const CreateMotionComponentDefault = () => {
        const classes = useClasses();

        return (
          <div className={classes.container}>
           <svg viewBox="0 0 ${compWidth} ${compHeight}" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style={{position: 'absolute', overflow: 'visible',}}>`,
    layers: [],
    end: `
          </svg>
          </div>
        );
      };
    `
  };
  // ${document.getElementsByTagName("defs")[0].outerHTML}
  const stylesObject = {};
  const motionComponents = new Set();
  const assetMap = {};
  // Create a map for assets and generate dynamic imports
  const assetIdentifiers = [];
  if (Array.isArray(lottieJSON.assets)) {
    lottieJSON.assets.forEach((asset, index) => {
      const assetId = `image_${index}`;
      assetMap[asset.id] = assetId;
      assetIdentifiers.push(assetId);
    });
    if (lottieJSON.assets.length > 0) {
      reactCode.dynamicImports += `import { ${assetIdentifiers.join(', ')} } from './assets/images.js';\n`;
    }
  }


  if (Array.isArray(lottieJSON.layers)) {
    lottieJSON.layers.reverse().forEach((layer, i) => {
      const layerId = `layer-${i}`;
      const motionComponentName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));

      const motionComponentCode = createMotionComponentCode(layer);
      if (motionComponentCode) {
        const unquotedMotionComponentCode = motionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
        const unquotedInfinity = unquotedMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
        motionComponents.add(`const ${motionComponentName} = createMotionComponent(${unquotedInfinity});`);
      }

      switch (layer.ty) {
        case 0:
        case 1:
          reactCode.layers.push(convertSolidLayerToReact(layer, i, motionComponents));
          break;
        case 2:
          reactCode.layers.push(convertImageLayerToReact(layer, assetMap, motionComponents, lottieJSON.assets));
          break;
        case 3:
          // console.log(layer)
          // reactCode.layers.push(createLayerJSX(layer, motionComponentName, assetMap));
          break;
        case 4:
          reactCode.layers.push(convertShapeLayerToReact(layer, i, motionComponents));
          break;
        default:
          break;
      }


      // if (layer.ty === 4) {

      //   let styles = {
      //     position: 'absolute',

      //   };



      //   stylesObject[`${capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind))}_wrapper`] = styles;
      // }

      if (layer.refId) {
        // Image Handling

        lottieJSON.assets.forEach((asset) => {
          if (asset.id === layer.refId) {
            let srcHeight = asset.h;
            let srcWidth = asset.w;
            let styles = {
              position: 'absolute',
            };
            // console.log('layer', layer)
            // Check if scale is not animated and set width and height
            if (layer.ks.s.a === 0 && Array.isArray(layer.ks.s.k)) {
              let scaleX = layer.ks.s.k[0];
              let scaleY = layer.ks.s.k[1];

              // Ensure scale values are numbers
              if (typeof scaleX === 'number' && typeof scaleY === 'number') {
                styles.width = `${srcWidth * scaleX / 100}px`;
                styles.height = `${srcHeight * scaleY / 100}px`;
                let matrixVals = calculateLayerTransformMatrix(layer)
                styles.transform = `matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})`
                // styles.transform = `translate(${(srcWidth * scaleX / 100)/-2}px, ${(srcHeight * scaleY / 100)/-2}px)`;
              }
            } else {

              let scaleX = layer.ks.s.k[0].s[0];
              let scaleY = layer.ks.s.k[0].s[1];
              console.log(srcHeight, scaleY)
              styles.width = `${srcWidth * scaleX / 100}px`;
              styles.height = `${srcHeight * scaleY / 100}px`;
              let matrixVals = calculateLayerTransformMatrix(layer)
              styles.transform = `matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})`

            }

            // Check if rotation is not animated and set rotation
            if (layer.ks.r.a === 0 && typeof layer.ks.r.k === 'number') {
              styles.transform = (styles.transform || '');
            }

            // Check if opacity is not animated and set opacity
            if (layer.ks.o.a === 0 && typeof layer.ks.o.k === 'number') {
              styles.opacity = layer.ks.o.k / 100;
            }

            // stylesObject[`${capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind))}_wrapper`] = styles;

          }
        });
      }



    });


  } else {
    console.error("lottieJSON.layers is not an array or is undefined");
  }

  reactCode.motionComponents = Array.from(motionComponents).join('\n');

  reactCode.styles = `
    const useClasses = makeStyles({
      container: {
        position: 'relative',
        overflow: 'hidden',
        width: "${lottieJSON.w}px",
        height: "${lottieJSON.h}px",
        backgroundColor: '${lottiePreviewBG}',
        border: '2px dotted rgb(110, 34, 241)',
      },
      ${Object.keys(stylesObject).map(layer => `"${layer}": ${JSON.stringify(stylesObject[layer])}`).join(',\n')}
    });
  `;

  return `
    ${reactCode.imports}
    ${reactCode.dynamicImports}
    ${reactCode.styles}
    ${reactCode.motionComponents}
    ${reactCode.component}
    ${reactCode.layers.join('\n')}
    ${reactCode.end}
  `;
}

function createLayerJSX(layer, motionComponentName, assetMap) {

  if (layer.ty === 2 && assetMap[layer.refId]) {
    const assetId = assetMap[layer.refId];
    //console.log(assetMap)
    return `
      <div className={classes.${motionComponentName}_wrapper}>
      <${motionComponentName}>
        <img 
          src={${assetId}} 
          id="${cleanName(layer.nm + "_" + layer.ind)}" 
          alt="${layer.nm}"
        />
      </${motionComponentName}>
      </div>
    `;
  } else {
    return `
      <${motionComponentName}>
        <div id="${cleanName(layer.nm + "_" + layer.ind)}" className={classes.${cleanName(layer.nm + "_" + layer.ind)}}></div>
      </${motionComponentName}>
    `;
  }
}

function timeToKeyframeOffset(kf) {
  const frame = kf.t;
  let res = frame / totalFrames;
  if (res > 1) {
    res = 1
  }
  return parseFloat(res.toFixed(3));
}

function calcMotionCurve(kf) {
  if (kf.i && kf.o) {
    const x1 = Array.isArray(kf.i.x) ? kf.i.x[0] : kf.i.x;
    const y1 = Array.isArray(kf.i.y) ? kf.i.y[0] : kf.i.y;
    const x2 = Array.isArray(kf.o.x) ? kf.o.x[0] : kf.o.x;
    const y2 = Array.isArray(kf.o.y) ? kf.o.y[0] : kf.o.y;

    if (x1 !== undefined && y1 !== undefined && x2 !== undefined && y2 !== undefined) {
      const cubicBezier = `cubic-bezier(${x2}, ${y2}, ${x1}, ${y1})`.replace(/\s+/g, '');
      for (const [key, value] of Object.entries(motionTokens)) {
        if (value === cubicBezier) {
          return `motionTokens.${key}`;
        }
      }
      return cubicBezier;
    }
  }
  return 'linear';
}




function calcDurationToken(duration) {
  for (const [key, value] of Object.entries(motionTokens)) {
    if (value === duration) {
      return `motionTokens.${key}`;
    }
  }
  return duration;
}



function createMotionComponentCode(item) {
  const keyframes = [];

  const addKeyframeWithOffset1 = (kfArray) => {
    const lastKeyframe = kfArray[kfArray.length - 1];
    if (lastKeyframe && lastKeyframe.offset !== 1) {
      kfArray.push({
        ...lastKeyframe,
        offset: 1,
      });
    }
  };

  const createPositionKeyframes = () => {
    if (item.ks?.p?.a) {
      const initialKeyframe = item.ks.p.k[0].s;
      let initXScaleValue = 1;
      let initYScaleValue = 1;
      if (item.ks?.s?.a) {
        initXScaleValue = item.ks.s.k[0].s[0] / 100;
        initYScaleValue = item.ks.s.k[0].s[1] / 100;
      } else {
        initXScaleValue = item.ks.s.k[0] / 100;
        initYScaleValue = item.ks.s.k[1] / 100;
      }

      const keyframes = [{
        transform: `translate(${0}px, ${0}px)`,
        offset: 0,
        easing: 'linear',
        composite: 'accumulate', // Example composite setting
      }];
      keyframes.push(...item.ks.p.k.map((kf) => ({
        transform: `translate(${round((kf.s[0] - initialKeyframe[0]) / initXScaleValue)}px, ${round((kf.s[1] - initialKeyframe[1]) / initYScaleValue)}px)`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'accumulate', // Example composite setting
      })));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createScaleKeyframes = () => {
    if (item.ks?.s?.a) {
      const keyframes = [];
      const initialKeyframe = item.ks.s.k[0].s;
      const initialScaleX = initialKeyframe[0] / 100;
      const initialScaleY = initialKeyframe[1] / 100;

      keyframes.push({
        transform: `scale(1, 1)`,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      });

      item.ks.s.k.forEach((kf) => {
        const scaleX = (kf.s[0] / 100) / initialScaleX;
        const scaleY = (kf.s[1] / 100) / initialScaleY;
        keyframes.push({
          transform: `scale(${scaleX}, ${scaleY})`,
          offset: timeToKeyframeOffset(kf),
          easing: calcMotionCurve(kf),
          composite: 'add',
        });
      });

      addKeyframeWithOffset1(keyframes);

      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createRotationKeyframes = () => {
    if (item.ks?.r?.a) {
      const initialKeyframe = item.ks.r.k[0].s;
      const keyframes = [{
        transform: `rotate(${initialKeyframe}deg)`,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      }];
      keyframes.push(...item.ks.r.k.map((kf) => ({
        transform: `rotate(${kf.s}deg)`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'add',
      })));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };


    const createRxKeyframes = (shapeItem) => {
    if (shapeItem.r?.a) {  // Checking if 'r' is animated
      const initialRx = shapeItem.r.k[0].s[0];
      const rxKeyframes = [{
        rx: `${initialRx}px`,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      }];
      rxKeyframes.push(...shapeItem.r.k.map((kf) => ({
        rx: `${kf.s[0]}px`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'add',
      })));
      addKeyframeWithOffset1(rxKeyframes);
      return {
        keyframes: rxKeyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createRxRyKeyframes = (shapeItem) => {
    if (shapeItem.s.a) {  // Checking if 's' (size) is animated
      const rxRyKeyframes = [];
      shapeItem.s.k.forEach((kf) => {
        const width = kf.s[0];
        const height = kf.s[1];
        const rx = width / 2;
        const ry = height / 2;

        rxRyKeyframes.push({
          rx: `${rx}px`,
          ry: `${ry}px`,
          offset: timeToKeyframeOffset(kf),
          easing: calcMotionCurve(kf),
          composite: 'add',
        });
      });

      addKeyframeWithOffset1(rxRyKeyframes);

      return {
        keyframes: rxRyKeyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createSizeKeyframes = (shapeItem) => {
    if (shapeItem.s.a) {  // Checking if 's' (size) is animated
      const initialSize = shapeItem.s.k[0].s;
      const sizeKeyframes = [{
        width: `${initialSize[0]}px`,
        height: `${initialSize[1]}px`,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      }];
      sizeKeyframes.push(...shapeItem.s.k.map((kf) => ({
        width: `${kf.s[0]}px`,
        height: `${kf.s[1]}px`,
        transform: `translate(${(initialSize[0] - kf.s[0]) / 2}px,${(initialSize[1] - kf.s[1]) / 2}px)`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'add',
      })));
      addKeyframeWithOffset1(sizeKeyframes);
      return {
        keyframes: sizeKeyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const positionTimeline = createPositionKeyframes();
  if (positionTimeline) keyframes.push(positionTimeline);

  const scaleTimeline = createScaleKeyframes();
  if (scaleTimeline) keyframes.push(scaleTimeline);

  const rotateTimeline = createRotationKeyframes();
  if (rotateTimeline) keyframes.push(rotateTimeline);

  if (item.ks?.o?.a) {
    const opacityKeyframes = [{
      opacity: item.ks.o.k[0].s / 100,
      offset: 0,
      easing: 'linear',
      composite: 'add',
    }];
    opacityKeyframes.push(...item.ks.o.k.map((kf) => ({
      opacity: kf.s / 100,
      offset: timeToKeyframeOffset(kf),
      easing: calcMotionCurve(kf),
    })));
    addKeyframeWithOffset1(opacityKeyframes);
    if (opacityKeyframes.length > 0) {
      keyframes.push({
        keyframes: opacityKeyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
        composite: 'add',
      });
    }
  }

  // Layer Fill Animation
  if (item.ef) {
    item.ef.forEach((effect) => {
      if (effect.mn === "ADBE Fill") {
        effect.ef.forEach((effectProp) => {
          if (effectProp.mn === "ADBE Fill-0002" && effectProp.v.a) {
            const initialFill = effectProp.v.k[0].s;
            const fillKeyframes = [{
              fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
              offset: 0,
              easing: 'linear',
              composite: 'add',
            }];
            fillKeyframes.push(...effectProp.v.k.map((kf) => ({
              fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
              offset: timeToKeyframeOffset(kf),
              easing: calcMotionCurve(kf),
              composite: 'add',
            })));
            addKeyframeWithOffset1(fillKeyframes);
            if (fillKeyframes.length > 0) {
              keyframes.push({
                keyframes: fillKeyframes,
                duration: compDurationMilliseconds,
                iterations: 'Infinity',
              });
            }
          }
        });
      }
    });
  }

  // Shape Fill, Size, and Rx/Ry Animation
  if (item.shapes) {
    item.shapes.forEach((shape) => {
      if (shape.it) {
        shape.it.forEach((shapeItem) => {
          if (shapeItem.ty === 'fl' && shapeItem.c.a) {
            const initialFill = shapeItem.c.k[0].s;
            const fillKeyframes = [{
              fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
              offset: 0,
              easing: 'linear',
              composite: 'add',
            }];
            fillKeyframes.push(...shapeItem.c.k.map((kf) => ({
              fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
              offset: timeToKeyframeOffset(kf),
              easing: calcMotionCurve(kf),
              composite: 'add',
            })));
            addKeyframeWithOffset1(fillKeyframes);
            if (fillKeyframes.length > 0) {
              keyframes.push({
                keyframes: fillKeyframes,
                duration: compDurationMilliseconds,
                iterations: 'Infinity',
              });
            }
          }

          // Add size keyframes only for 'rc' types (rectangles)
          if (shapeItem.ty === 'rc') {
            const sizeTimeline = createSizeKeyframes(shapeItem);
            if (sizeTimeline) keyframes.push(sizeTimeline);
          }

          // Add rx keyframes only for 'rc' types (rectangles)
          if (shapeItem.ty === 'rc') {
            const rxTimeline = createRxKeyframes(shapeItem);
            if (rxTimeline) keyframes.push(rxTimeline);
          }

          // Add rx and ry keyframes only for 'el' types (ellipses)
          if (shapeItem.ty === 'el') {
            const rxRyTimeline = createRxRyKeyframes(shapeItem);
            if (rxRyTimeline) keyframes.push(rxRyTimeline);
          }
        });
      }
    });
  }

  return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
}


//Perf but not for cirlces
// function createMotionComponentCode(item) {
//   const keyframes = [];

//   const addKeyframeWithOffset1 = (kfArray) => {
//     const lastKeyframe = kfArray[kfArray.length - 1];
//     if (lastKeyframe && lastKeyframe.offset !== 1) {
//       kfArray.push({
//         ...lastKeyframe,
//         offset: 1,
//       });
//     }
//   };

//   const createPositionKeyframes = () => {
//     if (item.ks?.p?.a) {
//       const initialKeyframe = item.ks.p.k[0].s;
//       let initXScaleValue = 1;
//       let initYScaleValue = 1;
//       if (item.ks?.s?.a) {
//         initXScaleValue = item.ks.s.k[0].s[0] / 100;
//         initYScaleValue = item.ks.s.k[0].s[1] / 100;
//       } else {
//         initXScaleValue = item.ks.s.k[0] / 100;
//         initYScaleValue = item.ks.s.k[1] / 100;
//       }

//       const keyframes = [{
//         transform: `translate(${0}px, ${0}px)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'accumulate', // Example composite setting
//       }];
//       keyframes.push(...item.ks.p.k.map((kf) => ({
//         transform: `translate(${round((kf.s[0] - initialKeyframe[0]) / initXScaleValue)}px, ${round((kf.s[1] - initialKeyframe[1]) / initXScaleValue)}px)`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'accumulate', // Example composite setting
//       })));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const createScaleKeyframes = () => {
//     if (item.ks?.s?.a) {
//       const keyframes = [];
//       const initialKeyframe = item.ks.s.k[0].s;
//       const initialScaleX = initialKeyframe[0] / 100;
//       const initialScaleY = initialKeyframe[1] / 100;

//       keyframes.push({
//         transform: `scale(1, 1)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       });

//       item.ks.s.k.forEach((kf) => {
//         const scaleX = (kf.s[0] / 100) / initialScaleX;
//         const scaleY = (kf.s[1] / 100) / initialScaleY;
//         keyframes.push({
//           transform: `scale(${scaleX}, ${scaleY})`,
//           offset: timeToKeyframeOffset(kf),
//           easing: calcMotionCurve(kf),
//           composite: 'add',
//         });
//       });

//       addKeyframeWithOffset1(keyframes);

//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const createRotationKeyframes = () => {
//     if (item.ks?.r?.a) {
//       const initialKeyframe = item.ks.r.k[0].s;
//       const keyframes = [{
//         transform: `rotate(${initialKeyframe}deg)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       }];
//       keyframes.push(...item.ks.r.k.map((kf) => ({
//         transform: `rotate(${kf.s}deg)`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add',
//       })));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const createRxKeyframes = (shapeItem) => {
//     if (shapeItem.r?.a) {  // Checking if 'r' is animated
//       const initialRx = shapeItem.r.k[0].s[0];
//       const rxKeyframes = [{
//         rx: `${initialRx}px`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       }];
//       rxKeyframes.push(...shapeItem.r.k.map((kf) => ({
//         rx: `${kf.s[0]}px`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add',
//       })));
//       addKeyframeWithOffset1(rxKeyframes);
//       return {
//         keyframes: rxKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const createSizeKeyframes = (shapeItem) => {
//     if (shapeItem.s.a) {  // Checking if 's' (size) is animated
//       const initialSize = shapeItem.s.k[0].s;
//       const sizeKeyframes = [{
//         width: `${initialSize[0]}px`,
//         height: `${initialSize[1]}px`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       }];
//       sizeKeyframes.push(...shapeItem.s.k.map((kf) => ({
//         width: `${kf.s[0]}px`,
//         height: `${kf.s[1]}px`,
//         transform: `translate(${(initialSize[0] - kf.s[0]) / 2}px,${(initialSize[1] - kf.s[1]) / 2}px)`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add',
//       })));
//       addKeyframeWithOffset1(sizeKeyframes);
//       return {
//         keyframes: sizeKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const positionTimeline = createPositionKeyframes();
//   if (positionTimeline) keyframes.push(positionTimeline);

//   const scaleTimeline = createScaleKeyframes();
//   if (scaleTimeline) keyframes.push(scaleTimeline);

//   const rotateTimeline = createRotationKeyframes();
//   if (rotateTimeline) keyframes.push(rotateTimeline);

//   if (item.ks?.o?.a) {
//     const opacityKeyframes = [{
//       opacity: item.ks.o.k[0].s / 100,
//       offset: 0,
//       easing: 'linear',
//       composite: 'add',
//     }];
//     opacityKeyframes.push(...item.ks.o.k.map((kf) => ({
//       opacity: kf.s / 100,
//       offset: timeToKeyframeOffset(kf),
//       easing: calcMotionCurve(kf),
//     })));
//     addKeyframeWithOffset1(opacityKeyframes);
//     if (opacityKeyframes.length > 0) {
//       keyframes.push({
//         keyframes: opacityKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//         composite: 'add',
//       });
//     }
//   }

//   // Layer Fill Animation
//   if (item.ef) {
//     item.ef.forEach((effect) => {
//       if (effect.mn === "ADBE Fill") {
//         effect.ef.forEach((effectProp) => {
//           if (effectProp.mn === "ADBE Fill-0002" && effectProp.v.a) {
//             const initialFill = effectProp.v.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...effectProp.v.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   // Shape Fill, Size, and Rx Animation
//   if (item.shapes) {
//     item.shapes.forEach((shape) => {
//       if (shape.it) {
//         shape.it.forEach((shapeItem) => {
//           if (shapeItem.ty === 'fl' && shapeItem.c.a) {
//             const initialFill = shapeItem.c.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...shapeItem.c.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//               });
//             }
//           }

//           // Add size keyframes for both 'rc' and 'el' types
//           if (shapeItem.ty === 'rc' || shapeItem.ty === 'el') {
//             const sizeTimeline = createSizeKeyframes(shapeItem);
//             if (sizeTimeline) keyframes.push(sizeTimeline);
//           }

//           // Add rx keyframes only for 'rc' types (rectangles)
//           if (shapeItem.ty === 'rc') {
//             const rxTimeline = createRxKeyframes(shapeItem);
//             if (rxTimeline) keyframes.push(rxTimeline);
//           }
//         });
//       }
//     });
//   }

//   return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
// }


//Perfect but not animating rx
// function createMotionComponentCode(item) {
//   const keyframes = [];

//   const addKeyframeWithOffset1 = (kfArray) => {
//     const lastKeyframe = kfArray[kfArray.length - 1];
//     if (lastKeyframe && lastKeyframe.offset !== 1) {
//       kfArray.push({
//         ...lastKeyframe,
//         offset: 1,
//       });
//     }
//   };

//   const createPositionKeyframes = () => {
//     if (item.ks?.p?.a) {
//       const initialKeyframe = item.ks.p.k[0].s;
//       let initXScaleValue = 1
//       let initYScaleValue = 1
//       if (item.ks?.s?.a) {
//         initXScaleValue = item.ks.s.k[0].s[0] / 100
//         initYScaleValue = item.ks.s.k[0].s[1] / 100
//       } else {
//         initXScaleValue = item.ks.s.k[0] / 100
//         initYScaleValue = item.ks.s.k[1] / 100
//       }

//       const keyframes = [{
//         transform: `translate(${0}px, ${0}px)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'accumulate', // Example composite setting
//       }];
//       keyframes.push(...item.ks.p.k.map((kf) => ({
//         transform: `translate(${round((kf.s[0] - initialKeyframe[0]) / initXScaleValue)}px, ${round((kf.s[1] - initialKeyframe[1]) / initXScaleValue)}px)`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'accumulate', // Example composite setting
//       })));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };


//   const createScaleKeyframes = () => {
//     if (item.ks?.s?.a) {
//       // Initialize keyframes array
//       const keyframes = [];

//       // Get the initial scale values
//       const initialKeyframe = item.ks.s.k[0].s;
//       const initialScaleX = initialKeyframe[0] / 100;
//       const initialScaleY = initialKeyframe[1] / 100;

//       // Calculate the baseline scale to be 1,1
//       keyframes.push({
//         transform: `scale(1, 1)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       });

//       // Process and add subsequent keyframes with delta calculation
//       item.ks.s.k.forEach((kf) => {
//         const scaleX = (kf.s[0] / 100) / initialScaleX;
//         const scaleY = (kf.s[1] / 100) / initialScaleY;
//         keyframes.push({
//           transform: `scale(${scaleX}, ${scaleY})`,
//           offset: timeToKeyframeOffset(kf),
//           easing: calcMotionCurve(kf),
//           composite: 'add',
//         });
//       });

//       // Ensure keyframes are adjusted correctly with offset 1
//       addKeyframeWithOffset1(keyframes);

//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };



//   const createRotationKeyframes = () => {
//     if (item.ks?.r?.a) {
//       const initialKeyframe = item.ks.r.k[0].s;
//       const keyframes = [{
//         transform: `rotate(${initialKeyframe}deg)`,
//         offset: 0,
//         easing: 'linear',
//         composite: 'add', // Example composite setting
//       }];
//       keyframes.push(...item.ks.r.k.map((kf) => ({
//         transform: `rotate(${kf.s}deg)`,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add', // Example composite setting
//       })));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const positionTimeline = createPositionKeyframes();
//   if (positionTimeline) keyframes.push(positionTimeline);

//   const scaleTimeline = createScaleKeyframes();
//   if (scaleTimeline) keyframes.push(scaleTimeline);

//   const rotateTimeline = createRotationKeyframes();
//   if (rotateTimeline) keyframes.push(rotateTimeline);

//   if (item.ks?.o?.a) {
//     const opacityKeyframes = [{
//       opacity: item.ks.o.k[0].s / 100,
//       offset: 0,
//       easing: 'linear',
//       composite: 'add',
//     }];
//     opacityKeyframes.push(...item.ks.o.k.map((kf) => ({
//       opacity: kf.s / 100,
//       offset: timeToKeyframeOffset(kf),
//       easing: calcMotionCurve(kf),
//     })));
//     addKeyframeWithOffset1(opacityKeyframes);
//     if (opacityKeyframes.length > 0) {
//       keyframes.push({
//         keyframes: opacityKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//         composite: 'add',
//       });
//     }
//   }

//   // Layer Fill Animation
//   if (item.ef) {
//     item.ef.forEach((effect) => {
//       if (effect.mn === "ADBE Fill") {
//         effect.ef.forEach((effectProp) => {
//           if (effectProp.mn === "ADBE Fill-0002" && effectProp.v.a) {
//             const initialFill = effectProp.v.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...effectProp.v.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   // Shape Fill and Size Animation
//   if (item.shapes) {
//     item.shapes.forEach((shape) => {
//       if (shape.it) {
//         shape.it.forEach((shapeItem) => {
//           if (shapeItem.ty === 'fl' && shapeItem.c.a) {
//             const initialFill = shapeItem.c.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...shapeItem.c.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//               });
//             }
//           }

//           // Add keyframes for 'rc' type (rectangles) with animated 's' property (size)
//           if (shapeItem.ty === 'rc' && shapeItem.s.a) {
//             const initialSize = shapeItem.s.k[0].s;
//             const sizeKeyframes = [{
//               width: `${initialSize[0]}px`,
//               height: `${initialSize[1]}px`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             // console.log('sizeKeyframes', shapeItem.s.k)
//             sizeKeyframes.push(...shapeItem.s.k.map((kf) => ({
//               width: `${kf.s[0]}px`,
//               height: `${kf.s[1]}px`,
//               transform: `translate(${(initialSize[0] - kf.s[0]) / 2}px,${(initialSize[1] - kf.s[1]) / 2}px)`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(sizeKeyframes);
//             if (sizeKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: sizeKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
// }


//Best
// function createMotionComponentCode(item) {
//   const keyframes = [];

//   const addKeyframeWithOffset1 = (kfArray) => {
//     const lastKeyframe = kfArray[kfArray.length - 1];
//     if (lastKeyframe && lastKeyframe.offset !== 1) {
//       kfArray.push({
//         ...lastKeyframe,
//         offset: 1,
//       });
//     }
//   };

//   const createKeyframes = (property, transformFunc) => {
//     if (item.ks?.[property]?.a) {
//       const initialKeyframe = item.ks[property].k[0].s;
//       const keyframes = [{
//         transform: transformFunc(initialKeyframe, initialKeyframe),
//         offset: 0,
//         easing: 'linear',
//         composite: 'add',
//       }];
//       keyframes.push(...item.ks[property].k.map((kf) => ({
//         transform: transformFunc(kf.s, initialKeyframe),
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add',
//       })));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   const positionTimeline = createKeyframes('p', (s, initial) => `translate(${s[0] - initial[0]}px, ${s[1] - initial[1]}px)`);
//   if (positionTimeline) keyframes.push(positionTimeline);

//   const scaleTimeline = createKeyframes('s', (s, initial) => `scale(${s[0] / initial[0]}, ${s[1] / initial[1]})`);
//   if (scaleTimeline) keyframes.push(scaleTimeline);

//   const rotateTimeline = createKeyframes('r', (s, initial) => `rotate(${s - initial}deg)`);
//   if (rotateTimeline) keyframes.push(rotateTimeline);

//   if (item.ks?.o?.a) {
//     const opacityKeyframes = [{
//       opacity: item.ks.o.k[0].s / 100,
//       offset: 0,
//       easing: 'linear',
//       composite: 'add',
//     }];
//     opacityKeyframes.push(...item.ks.o.k.map((kf) => ({
//       opacity: kf.s / 100,
//       offset: timeToKeyframeOffset(kf),
//       easing: calcMotionCurve(kf),
//     })));
//     addKeyframeWithOffset1(opacityKeyframes);
//     if (opacityKeyframes.length > 0) {
//       keyframes.push({
//         keyframes: opacityKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//         composite: 'add',
//       });
//     }
//   }

//   // Layer Fill Animation
//   if (item.ef) {
//     item.ef.forEach((effect) => {
//       if (effect.mn === "ADBE Fill") {
//         effect.ef.forEach((effectProp) => {
//           if (effectProp.mn === "ADBE Fill-0002" && effectProp.v.a) {
//             const initialFill = effectProp.v.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...effectProp.v.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//                 composite: 'add',
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   // Shape Fill Animation
//   if (item.shapes) {
//     item.shapes.forEach((shape) => {
//       if (shape.it) {
//         shape.it.forEach((shapeItem) => {
//           if (shapeItem.ty === 'fl' && shapeItem.c.a) {
//             const initialFill = shapeItem.c.k[0].s;
//             const fillKeyframes = [{
//               fill: `rgba(${initialFill[0] * 255}, ${initialFill[1] * 255}, ${initialFill[2] * 255}, ${initialFill[3]})`,
//               offset: 0,
//               easing: 'linear',
//               composite: 'add',
//             }];
//             fillKeyframes.push(...shapeItem.c.k.map((kf) => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add',
//             })));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//                 composite: 'add',
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
// }



//works great!
// function createMotionComponentCode(item) {
//   const keyframes = [];

//   const addKeyframeWithOffset1 = (kfArray) => {
//     const lastKeyframe = kfArray[kfArray.length - 1];
//     if (lastKeyframe.offset !== 1) {
//       kfArray.push({
//         ...lastKeyframe,
//         offset: 1
//       });
//     }
//   };

//   // Helper function to create keyframes for a property
//   const createKeyframes = (property, transformFunc) => {
//     if (item.ks?.[property]?.a) {
//       const initialKeyframe = item.ks[property].k[0].s;
//       const keyframes = item.ks[property].k.map(kf => ({
//         transform: transformFunc(kf.s, initialKeyframe),
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add'
//       }));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity'
//       };
//     }
//     return null;
//   };

//   const positionTimeline = createKeyframes('p', (s, initial) => `translate(${s[0] - initial[0]}px, ${s[1] - initial[1]}px)`);
//   if (positionTimeline) keyframes.push(positionTimeline);

//   const scaleTimeline = createKeyframes('s', (s, initial) => `scale(${s[0] / initial[0]}, ${s[1] / initial[1]})`);
//   if (scaleTimeline) keyframes.push(scaleTimeline);

//   const rotateTimeline = createKeyframes('r', (s, initial) => `rotate(${s - initial}deg)`);
//   if (rotateTimeline) keyframes.push(rotateTimeline);

//   if (item.ks?.o?.a) {
//     const opacityKeyframes = item.ks.o.k.map(kf => ({
//       opacity: (kf.s) / 100,
//       offset: timeToKeyframeOffset(kf),
//       easing: calcMotionCurve(kf),
//     }));
//     addKeyframeWithOffset1(opacityKeyframes);
//     if (opacityKeyframes.length > 0) {
//       keyframes.push({
//         keyframes: opacityKeyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       });
//     }
//   }

//   // Handle fill color animation for shapes
//   if (item.shapes) {
//     item.shapes.forEach(shape => {
//       if(shape.it){
//         shape.it.forEach(shapeItem => {
//           if (shapeItem.ty === 'fl' && shapeItem.c.a) {
//             const initialFill = shapeItem.c.k[0].s;
//             const fillKeyframes = shapeItem.c.k.map(kf => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add'
//             }));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//                 composite: 'add'
//               });
//             }
//           }
//         });
//       }

//     });
//   }

//   // Handle fill color animation for fill effects on the layer
//   if (item.ef) {
//     item.ef.forEach(effect => {
//       if (effect.ty === 21) { // Fill effect type
//         effect.ef.forEach(effectItem => {
//           if (effectItem.nm === 'Color' && effectItem.v.a) {
//             const initialFill = effectItem.v.k[0].s;
//             const fillKeyframes = effectItem.v.k.map(kf => ({
//               fill: `rgba(${kf.s[0] * 255}, ${kf.s[1] * 255}, ${kf.s[2] * 255}, ${kf.s[3]})`,
//               offset: timeToKeyframeOffset(kf),
//               easing: calcMotionCurve(kf),
//               composite: 'add'
//             }));
//             addKeyframeWithOffset1(fillKeyframes);
//             if (fillKeyframes.length > 0) {
//               keyframes.push({
//                 keyframes: fillKeyframes,
//                 duration: compDurationMilliseconds,
//                 iterations: 'Infinity',
//                 composite: 'add'
//               });
//             }
//           }
//         });
//       }
//     });
//   }

//   return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
// }



function calculateTransformOrigin(layer, shape) {


  let layerMatrix = calculateLayerTransformMatrix(layer)
  let shapeMatrix = calculateShapeTransformMatrix(shape)
  // console.log(layerMatrix, shapeMatrix)
  let transformOriginX = '50%', transformOriginY = '50%'; // Initialize to 0%

  //If pos is not animated
  if (layer.ks?.p?.k) {
    //Is animated
    if (layer.ks?.p?.a) {
      let posX = layer.ks?.p?.k[0].s[0]
      let posY = layer.ks?.p?.k[0].s[1]
      // let toX = -((layerMatrix[4] - shapeMatrix[4]) - posX)
      // let toY = -((layerMatrix[5] + shapeMatrix[5]) + posY)
      let toX = -((layerMatrix[4] + shapeMatrix[4]) - posX)
      let toY = -((layerMatrix[5] + shapeMatrix[5]) - posY)
      let scaleXAdjustment = toX / layerMatrix[0]
      let scaleYAdjustment = toY / layerMatrix[3]
      transformOriginX = round(scaleXAdjustment) + 'px'
      transformOriginY = round(scaleYAdjustment) + 'px'
    } else {
      let posX = layer.ks?.p?.k[0]
      let posY = layer.ks?.p?.k[1]
      let toX = -((layerMatrix[4] + shapeMatrix[4]) - posX)
      let toY = -((layerMatrix[5] + shapeMatrix[5]) - posY)
      let scaleXAdjustment = toX / layerMatrix[0]
      let scaleYAdjustment = toY / layerMatrix[3]
      transformOriginX = round(scaleXAdjustment) + 'px'
      transformOriginY = round(scaleYAdjustment) + 'px'
      // transformOriginX = round(toX) + 'px'
      // transformOriginY = round(toY) + 'px'
    }

  }


  return [transformOriginX, transformOriginY];
}




// Convert number to string and check if it has more than two decimal places
function round(num) {
  // console.log(num);
  // Handle negative zero
  if (Object.is(num, -0)) {

    return 0;
  }
  // Check if number has more than 2 decimal places
  if (num.toString().split('.')[1]?.length > 2) {
    return parseFloat(num.toFixed(2));
  }
  return num;
}





// //Currently working Aug 12
function convertShapeLayerToReact(layer, layerIndex, motionComponents) {
  let reactCode = '';
  const initPos = layer.ks.p.a ? layer.ks.p.k[0].s : layer.ks.p.k;

  if (layer.shapes) {
    const motionComponentCode = createMotionComponentCode(layer);
    const motionComponentName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let wrapperClassName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let matrixVals = calculateLayerTransformMatrix(layer);
    reactCode += `<g id="${layer.ln}" transform='matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})' >`;
    if (motionComponentCode.length > 0) {
      const unquotedMotionComponentCode = motionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
      const unquotedInfinity = unquotedMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
      motionComponents.add(`const ${motionComponentName} = createMotionComponent(${unquotedInfinity});`);
      reactCode += `<${motionComponentName}>`;
    }

    layer.shapes.reverse().forEach((shape, shapeIndex) => {
      let layerFillAdded = false;
      if (layer.ef) {
        layer.ef.forEach((effect) => {
          if (effect.ty === 21) {
            layerFillAdded = true;
          }
        });
      }

      if (shape.ty === 'gr' && shape.it) {
        let groupContent = '';
        let shapeMotionComponentName = ''; // Define it here

        shape.it.forEach((shapeProps, k) => {
          let shapeElement = null;

          let shapeMotionComponentCode = createShapeMotionComponentCode(shapeProps);
          if (shapeMotionComponentCode.length > 0) {
            shapeMotionComponentName = capitalizeFirstLetter(cleanName(layer.ln + "_" + shape.ln + "_" + shape.ix));
            const unquotedShapeMotionComponentCode = shapeMotionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
            const unquotedShapeInfinity = unquotedShapeMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
            motionComponents.add(`const ${shapeMotionComponentName} = createMotionComponent(${unquotedShapeInfinity});`);
          }

          switch (shapeProps.ty) {
            case 'sh':
            case 'el':
            case 'sr':
            case 'rc':
              let elemName = layer.nm.slice(1);
              let parent = document.getElementById(elemName);
              let fillColor = "inherit";
              let fillOpacity = "inherit";
              let hasLayerFillEffect = false;
              let shapeType = null

              if (layer.ef) {
                layer.ef.forEach((effect) => {
                  if (effect.ty === 21) {
                    hasLayerFillEffect = true;
                    effect.ef.forEach((fillParam) => {
                      if (fillParam.mn === "ADBE Fill-0002" && !fillParam.v.a) {
                        fillColor = convertColor(fillParam.v.k);
                      }
                      if (fillParam.mn === "ADBE Fill-0005" && !fillParam.v.a) {
                        fillOpacity = fillParam.v.k;
                      }
                    });
                  }
                });
              }

              if (parent) {
                let childElements = parent.children;
                let combinedHTML = '';

                for (let i = 0; i < childElements.length; i++) {
                  let child = childElements[i];

                  if (child.getAttribute('data-processed')) {
                    continue;
                  }
                  let shapeLayerWrapperTransform = child.getAttribute('transform');
                  let curShape = null
                  //Find Shape
                  if (layer.shapes.length > 0) {

                    layer.shapes.forEach((shapeL) => {

                      if (shapeL.ln === child.getAttribute('id')) {
                        curShape = shapeL
                      }
                    })
                  }


                  let motionCompName = capitalizeFirstLetter(cleanName(layer.ln + "_" + curShape.ln + "_" + curShape.ix));

                  if (curShape && curShape.it && Array.isArray(curShape.it)) {
                    curShape.it.forEach((shapeLayer) => {
                      if (shapeLayer.ty === "tr") {
                        if ((shapeLayer.p.a || shapeLayer.r.a || shapeLayer.s.a || shapeLayer.o.a)) {
                          combinedHTML += `<${motionCompName}>`;
                        }
                        // console.log("curShape", curShape)
                      }
                      if (shapeLayer.ty === 'rc') {
                        shapeType = 'rc'
                      }
                      if (shapeLayer.ty === 'el') {
                        shapeType = 'el'
                      }
                      if (shapeLayer.ty === 'sh') {
                        shapeType = 'sh'
                      }
                      if (shapeLayer.ty === 'sr') {
                        shapeType = 'sr'
                      }
                    });
                  }

                  combinedHTML += `<g
                      key={${i}}
                      id="${curShape.ln}"
                      fill="${fillColor}"
                      fill-opacity="${fillOpacity}"
                      transform="${shapeLayerWrapperTransform}"
                      style={{
                        width: 'inherit',
                        height: 'inherit',
                        rx:'inherit',
                        ry:'inherit',
                        transformOrigin: '${calculateTransformOrigin(layer, curShape)[0]} ${calculateTransformOrigin(layer, curShape)[1]}'
                      }}>`;

                  // let originalInnerHTML = '';

                  // if (shape && shape.it && Array.isArray(shape.it)) {
                  //   shape.it.forEach((shapeEffect) => {
                  //     if ((shapeEffect.ty === "fl" && shapeEffect.c.a) || hasLayerFillEffect) {
                  //       let paths = child.getElementsByTagName('path');
                  //       let originalFills = [];
                  //       for (let j = 0; j < paths.length; j++) {
                  //         let path = paths[j];
                  //         originalFills.push(path.getAttribute('fill'));
                  //         path.setAttribute('fill', 'inherit');
                  //       }

                  //       originalInnerHTML = child.innerHTML

                  //       for (let j = 0; j < paths.length; j++) {
                  //         let path = paths[j];
                  //         path.setAttribute('fill', originalFills[j]);
                  //       }
                  //     } else {
                  //       originalInnerHTML = child.innerHTML;
                  //     }
                  //   });
                  // }

                  let originalInnerHTML = child.innerHTML;



                  //Handle SH and SR
                  if (curShape && curShape.it && Array.isArray(curShape.it) && shapeType !== "rc" && shapeType !== "el") {
                    curShape.it.forEach((shapeEffect) => {
                      //Handle Fill Anim
                      if ((shapeEffect.ty === "fl" && shapeEffect.c.a) || hasLayerFillEffect) {
                        let paths = child.getElementsByTagName('path');
                        let originalFills = [];
                        for (let j = 0; j < paths.length; j++) {
                          let path = paths[j];
                          originalFills.push(path.getAttribute('fill'));
                          path.setAttribute('fill', 'inherit');
                        }

                        originalInnerHTML = child.innerHTML

                        for (let j = 0; j < paths.length; j++) {
                          let path = paths[j];
                          path.setAttribute('fill', originalFills[j]);
                        }
                      }
                    });
                  }

                  //Handel Rects
                  if (curShape && curShape.it && Array.isArray(curShape.it) && shapeType === "rc") {
                    let fillColor = 'inherit'
                    let strokeColor = 'inherit'
                    let strokeOpacity = 1
                    let strokeWidth = 1
                    let linecap = 'butt'
                    let miterlimit = 4
                    let linejoin = 'miter'
                    if (curShape.it) {
                      curShape.it.forEach((shapeEffect) => {
                        if (shapeEffect.ty === "fl") {
                          
                          //If static fill
                          if (!shapeEffect.c.a && !hasLayerFillEffect) {
                            fillColor = convertColor(shapeEffect.c.k)
                            console.log(curShape, fillColor, shapeEffect.c.k)

                          } else {
                            //If Animated fill

                          }
                        }

                        if (shapeEffect.ty === "st") {
                          strokeColor = convertColor(shapeEffect.c.k)
                          miterlimit = shapeEffect.ml
                          if (!Array.isArray(shapeEffect.o.k)) {
                            strokeOpacity = shapeEffect.o.k
                          }
                          if (shapeEffect.lj === 1) {
                            linejoin = "miter"
                          }
                          if (shapeEffect.lj === 2) {
                            linejoin = "round"
                          }
                          if (shapeEffect.lj === 2) {
                            linejoin = "bevel"
                          }
                          if (shapeEffect.lc === 1) {
                            linejoin = "butt"
                          }
                          if (shapeEffect.lc === 2) {
                            linejoin = "round"
                          }
                          if (shapeEffect.lc === 3) {
                            linejoin = "square"
                          }
                          if (!Array.isArray(shapeEffect.w.k)) {
                            strokeWidth = shapeEffect.w.k
                          }
                        }

                      })
                    }
                   let w=Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? shapeProps.s.k[0].s[0] : shapeProps.s.k[0]
                   let h= Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[1].s ? shapeProps.s.k[0].s[1] : shapeProps.s.k[1]
                   let minDimension = Math.min(w, h)/2;
                  
                   let rx='inherit'
                   if(Array.isArray(shapeProps.r.k) && shapeProps.r.k.length > 0 && shapeProps.s.k[0].s ){  
                   }else{
                    if(shapeProps.r.k>minDimension){
                      rx=minDimension
                    }else{
                      rx=shapeProps.r.k
                    }
                   }
                  //  let rx=Array.isArray(shapeProps.r.k) && shapeProps.r.k.length > 0 ? 'inherit' : shapeProps.r.k || 0
                    originalInnerHTML = `<rect 
                            key={${k}}
                            x="${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? shapeProps.s.k[0].s[0] / -2 || 0 : (shapeProps.s.k[0] / -2 || 0)}px"
                            y="${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? shapeProps.s.k[0].s[1] / -2 || 0 : (shapeProps.s.k[1] / -2 || 0)}px"
                            fill="${fillColor}"
                            stroke-linejoin="${linejoin}"
                            stroke-linecap="${linecap}"
                            stroke-miterlimit="${miterlimit}"
                            stroke-opacity="${strokeOpacity}"
                            stroke="${strokeColor}"
                            strokeWidth="${strokeWidth}"
                            style={{
                              width: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? 'inherit' : (shapeProps.s.k[0] + "px")}",
                              height: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? 'inherit' : (shapeProps.s.k[1] + "px")}",
                              rx: "${rx}"
                            }}
                            >
                          </rect>`
                  }

                  // Handle Ellipses
                  if (curShape && curShape.it && Array.isArray(curShape.it) && shapeType === "el") {
                    let fillColor = 'inherit';
                    let strokeColor = 'inherit';
                    let strokeOpacity = 1;
                    let strokeWidth = 1;
                    let linecap = 'butt';
                    let miterlimit = 4;
                    let linejoin = 'miter';
                    let rx = '100px';
                    let ry = '100px';
                    if (curShape.it) {
                      curShape.it.forEach((shapeEffect) => {
                        if (shapeEffect.ty === "fl" && !shapeEffect.c.a && !hasLayerFillEffect) {
                          fillColor = convertColor(shapeEffect.c.k);
                        }
                        if (shapeEffect.ty === "el") {
                          rx = Array.isArray(shapeEffect.s.k) && shapeEffect.s.k.length > 0  && shapeProps.s.k[0].s  ? 'inherit' : shapeEffect.s.k[0]/2
                          ry = Array.isArray(shapeEffect.s.k) && shapeEffect.s.k.length > 0  && shapeProps.s.k[0].s  ? 'inherit' : shapeEffect.s.k[1]/2
                        }

                        if (shapeEffect.ty === "st") {
                          strokeColor = convertColor(shapeEffect.c.k);
                          miterlimit = shapeEffect.ml;
                          if (!Array.isArray(shapeEffect.o.k)) {
                            strokeOpacity = shapeEffect.o.k;
                          }
                          if (shapeEffect.lj === 1) {
                            linejoin = "miter";
                          } else if (shapeEffect.lj === 2) {
                            linejoin = "round";
                          } else if (shapeEffect.lj === 3) {
                            linejoin = "bevel";
                          }
                          if (shapeEffect.lc === 1) {
                            linecap = "butt";
                          } else if (shapeEffect.lc === 2) {
                            linecap = "round";
                          } else if (shapeEffect.lc === 3) {
                            linecap = "square";
                          }
                          if (!Array.isArray(shapeEffect.w.k)) {
                            strokeWidth = shapeEffect.w.k;
                          }
                        }

                      });
                    }

                    const cx = Array.isArray(shapeProps.p.k) && shapeProps.p.k.length > 0 ? shapeProps.p.k[0]?.s?.[0] || shapeProps.p.k[0] || 0 : 0;
                    const cy = Array.isArray(shapeProps.p.k) && shapeProps.p.k.length > 0 ? shapeProps.p.k[0]?.s?.[1] || shapeProps.p.k[1] || 0 : 0;


                    originalInnerHTML = `<ellipse 
          key={${k}}
          cx="${cx}px"
          cy="${cy}px"
          fill="${fillColor}"
          stroke-linejoin="${linejoin}"
          stroke-linecap="${linecap}"
          stroke-miterlimit="${miterlimit}"
          stroke-opacity="${strokeOpacity}"
          stroke="${strokeColor}"
          strokeWidth="${strokeWidth}"
          style={{
            width: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 ? 'inherit' : (shapeProps.s.k[0] + "px")}",
            height: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 ? 'inherit' : (shapeProps.s.k[1] + "px")}",
            rx: "${rx}",
            ry: "${ry}"
          }}
          >
        </ellipse>`;
                  }



                  combinedHTML += originalInnerHTML;
                  combinedHTML += '</g>';



                  if (curShape && curShape.it && Array.isArray(curShape.it)) {
                    curShape.it.forEach((shapeLayer) => {
                      if (shapeLayer.ty === "tr") {
                        if (shapeLayer.p.a || shapeLayer.r.a || shapeLayer.s.a || shapeLayer.o.a) {
                          combinedHTML += `</${motionCompName}>`;
                        }
                      }
                    });
                  }
                  child.setAttribute('data-processed', 'true');
                }

                shapeElement = combinedHTML;
              } else {
                console.error(`Element with ID ${elemName} not found.`);
              }
              break;
            case 'gr':
              break;
            default:
              break;
          }

          if (shapeElement) {
            groupContent += shapeElement;
          }

          // if (shapeMotionComponentCode.length > 0) {
          //   groupContent += `</${shapeMotionComponentName}>`;
          // }
        });

        if (groupContent) {
          const groupMotionComponentCode = createMotionComponentCode(shape);
          if (layer.shapes.length > 1) {
            reactCode += `<g name="groupWrapper">${groupContent}</g>`;
          } else {
            reactCode += `${groupContent}`;
          }

          if (groupMotionComponentCode.length > 0) {
            reactCode += `</${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>`;
          }
        }
      }
    });

    if (motionComponentCode.length > 0) {
      reactCode += `</${motionComponentName}>`;
    }
    reactCode += '</g>';
  }

  return reactCode;
}


//Old from Aug 8th - Just using as reference
// function convertShapeLayerToReact(layer, layerIndex, motionComponents) {
//   let reactCode = '';
//   const initPos = layer.ks.p.a ? layer.ks.p.k[0].s : layer.ks.p.k;

//   if (layer.shapes) {
//     const motionComponentCode = createMotionComponentCode(layer);
//     const motionComponentName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
//     let wrapperClassName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
//     // reactCode += `<g className={classes.${wrapperClassName}_wrapper}>`;
//     let matrixVals = calculateLayerTransformMatrix(layer)

//     if (motionComponentCode.length > 0) {
//       const unquotedMotionComponentCode = motionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
//       const unquotedInfinity = unquotedMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
//       motionComponents.add(`const ${motionComponentName} = createMotionComponent(${unquotedInfinity});`);

//       reactCode += `<g id="${layer.ln}" transform='matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})'>`
//       reactCode += `<${motionComponentName}>`;

//     }

//     layer.shapes.reverse().forEach((shape, shapeIndex) => {
//       let layerFillAdded = false
//       if (layer.ef) {
//         layer.ef.forEach((effect) => {
//           if (effect.ty === 21) {
//             layerFillAdded = true
//           }
//         })
//       }

//       if (shape.ty === 'gr' && shape.it) {
//         let groupContent = '';
//         let groupStyles = {};
//         shape.it.forEach((shapeProps, k) => {
//           let shapeElement = null;
//           const pathStyles = {};

//           shape.it.forEach(prop => {
//             if (prop.ty === 'fl' && prop.c && !layerFillAdded) {
//               pathStyles.fill = convertColor(prop.c.k);
//             }
//             if (prop.ty === 'st') {
//               if (prop.c) pathStyles.stroke = convertColor(prop.c.k);
//               if (prop.w) pathStyles.strokeWidth = `${prop.w.k}px`;
//             }
//             if (prop.o) pathStyles.opacity = prop.o.k / 100;

//             if (prop.ty === 'tr') {
//               let transformString = '';

//               if (prop.p) {
//                 const position = prop.p.k;
//                 if (Array.isArray(position)) {
//                   pathStyles.position = 'absolute';
//                   // Omit pathStyles.top
//                   //pathStyles.left = `${position[0]}px`;

//                   transformString += `translate(${position[0]}, ${position[1]}) `;
//                 }
//               }

//               if (prop.r) {
//                 transformString += `rotate(${prop.r.k || 0}) `;
//               }

//               if (prop.s) {
//                 transformString += `scale(${prop.s.k[0] / 100}, ${prop.s.k[1] / 100}) `;
//               }

//               pathStyles.transform = transformString.trim(); // Remove any trailing spaces
//             }
//           });
//           //console.log('shapeProps',shapeProps)

//           let shapeMotionComponentCode = createShapeMotionComponentCode(shapeProps);

//           if (shapeMotionComponentCode.length > 0) {
//             const shapeMotionComponentName = capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex));
//             const unquotedShapeMotionComponentCode = shapeMotionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
//             const unquotedShapeInfinity = unquotedShapeMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
//             motionComponents.add(`const ${shapeMotionComponentName} = createMotionComponent(${unquotedShapeMotionComponentCode});`);
//           }


//           switch (shapeProps.ty) {
//             // case 'sr':
//             //   //   //Might breaking here
//             //   //   // shapeElement = shapeProps.s && shapeProps.s.k ? (
//             //   //   //   lottieShapeToPath(shapeProps, pathStyles, shape, layer)
//             //   //   // ) : null;
//             //   shapeElement = lottieShapeToPath(shapeProps, pathStyles, shape, layer)
//             //   break;
//             // case 'rc': {
//             //   let rectMatrixVals = calculateShapeTransformMatrix(shape)
//             //   shapeElement = shapeProps.s && shapeProps.p ? (
//             //     `<rect
//             //         key={${k}}
//             //         x="${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? shapeProps.s.k[0].s[0] / -2 || 0 : (shapeProps.s.k[0] / -2 || 0)}px"
//             //         y="${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? shapeProps.s.k[0].s[1] / -2 || 0 : (shapeProps.s.k[1] / -2 || 0)}px"
//             //         fill="${pathStyles.fill || 'inherit'}"
//             //         rx="${Array.isArray(shapeProps.r.k) && shapeProps.r.k.length > 0 ? shapeProps.r.k[0].s || 0 : shapeProps.r.k || 0}"
//             //         stroke="${pathStyles.stroke || 'inherit'}"
//             //         strokeWidth="${pathStyles.strokeWidth || 0}"
//             //         transform='${layer.shapes.length > 1 ? `matrix(${rectMatrixVals[0]}, ${rectMatrixVals[1]}, ${rectMatrixVals[2]}, ${rectMatrixVals[3]}, ${rectMatrixVals[4]}, ${rectMatrixVals[5]})` : 'inherit'}'
//             //         style={{
//             //           width: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? 'inherit' : (shapeProps.s.k[0]+"px")}",
//             //           height: "${Array.isArray(shapeProps.s.k) && shapeProps.s.k.length > 0 && shapeProps.s.k[0].s ? 'inherit' : (shapeProps.s.k[1]+"px")}"
//             //         }}
//             //       >
//             //       </rect>`
//             //   ) : null;
//             //   break;
//             // }
//             // case 'el':
//             //   let ellipseMatrixVals = calculateShapeTransformMatrix(shape)
//             //   shapeElement = shapeProps.s && shapeProps.s.k ? (
//             //     `<ellipse
//             //       key={${k}}
//             //       cx="${shapeProps.p.k[0]}px"
//             //       cy="${shapeProps.p.k[1]}px"
//             //       rx="${shapeProps.s.k[0] / 2}px"
//             //       ry="${shapeProps.s.k[1] / 2}px"
//             //       fill="${pathStyles.fill || 'none'}"
//             //       stroke="${pathStyles.stroke || 'none'}"
//             //       strokeWidth="${pathStyles.strokeWidth || 0}"
//             //       transform='${layer.shapes.length > 1 ? `matrix(${ellipseMatrixVals[0]}, ${ellipseMatrixVals[1]}, ${ellipseMatrixVals[2]}, ${ellipseMatrixVals[3]}, ${ellipseMatrixVals[4]}, ${ellipseMatrixVals[5]})` : 'inherit'}'
//             //     />`
//             //   ) : null;
//             //   break;
//             case 'sh':
//             case 'rc':
//             case 'el':
//             case 'sr':

//               let elemName = layer.nm.slice(1);
//               let parent = document.getElementById(elemName);
//               let fillColor = "inherit";
//               let fillOpacity = "inherit";
//               let hasLayerFillEffect = false;


//               if (layer.ef) {
//                 layer.ef.forEach((effect) => {
//                   if (effect.ty === 21) {
//                     hasLayerFillEffect = true;
//                     effect.ef.forEach((fillParam) => {
//                       // Apply fill to parent if not animated
//                       if (fillParam.mn === "ADBE Fill-0002" && !fillParam.v.a) {
//                         fillColor = convertColor(fillParam.v.k);
//                       }
//                       if (fillParam.mn === "ADBE Fill-0005" && !fillParam.v.a) {
//                         fillOpacity = fillParam.v.k;
//                       }
//                     });
//                   }
//                 });
//               }


//               if (parent) {

//                 let childElements = parent.children;
//                 let combinedHTML = ''; // Initialize an empty string to combine all HTML

//                 for (let i = 0; i < childElements.length; i++) {
//                   let child = childElements[i];
//                   // Check if the child has the 'data-processed' attribute
//                   if (child.getAttribute('data-processed')) {
//                     console.log('skipping')
//                     continue; // Skip this child if it has been processed
//                   }
//                   // console.log(parent.innerHTML, child)
//                   // console.log("Moname", motionCompName)
//                   // Extract Matrix Transform
//                   let shapeLayerWrapperTransform = child.getAttribute('transform');

//                   let motionCompName=capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))
//                   console.log(child.getAttribute('id'), shape.ln)
//                   if (shape && shape.it && Array.isArray(shape.it)) {
//                     console.log()
//                     //find matching shape
//                     shape.it.forEach((shapeLayer)=>{
//                       if(shapeLayer.ty==="tr"){
//                         if (shapeLayer.p.a || shapeLayer.r.a || shapeLayer.s.a || shapeLayer.o.a) {
//                              combinedHTML+=`<${motionCompName}>`
//                         }
//                       }
//                     })
//                   }

//                   combinedHTML += `<g
//                       key={0}
//                       id="${shape.ln}"
//                       fill="${fillColor}"
//                       fill-opacity="${fillOpacity}"
//                       transform="${shapeLayerWrapperTransform}"
//                       style={{
//                         width: 'inherit',
//                         height: 'inherit',
//                         transformOrigin: '${calculateTransformOrigin(layer, shape)[0]} ${calculateTransformOrigin(layer, shape)[1]}'
//                       }}>`;

//                   let originalInnerHTML = ''; // Initialize originalInnerHTML for combining

//                   if (shape && shape.it && Array.isArray(shape.it)) {
//                     shape.it.forEach((shapeEffect) => {
//                       if ((shapeEffect.ty === "fl" && shapeEffect.c.a) || hasLayerFillEffect) {
//                         let paths = child.getElementsByTagName('path');
//                         let originalFills = [];
//                         for (let j = 0; j < paths.length; j++) {
//                           let path = paths[j];
//                           originalFills.push(path.getAttribute('fill'));
//                           path.setAttribute('fill', 'inherit');
//                         }

//                         // Extract the innerHTML with the fill set to inherit
//                         originalInnerHTML = child.innerHTML;

//                         // Restore the original fill values
//                         for (let j = 0; j < paths.length; j++) {
//                           let path = paths[j];
//                           path.setAttribute('fill', originalFills[j]);
//                         }
//                       } else {
//                         // If no fill effect, just use the original innerHTML
//                         originalInnerHTML = child.innerHTML;
//                       }
//                     });
//                   }

//                   combinedHTML += originalInnerHTML; // Use the original innerHTML to combine the HTML of each child element
//                   combinedHTML += '</g>';


//                   if (shape && shape.it && Array.isArray(shape.it)) {
//                     shape.it.forEach((shapeLayer)=>{
//                       if(shapeLayer.ty==="tr"){
//                         if (shapeLayer.p.a || shapeLayer.r.a || shapeLayer.s.a || shapeLayer.o.a) {
//                              combinedHTML+=`</${motionCompName}>`
//                         }
//                       }
//                     })
//                   }

//                   // Set the 'data-processed' attribute to mark this child as processed
//                   child.setAttribute('data-processed', 'true');
//                 }

//                 shapeElement = combinedHTML;
//               } else {
//                 console.error(`Element with ID ${elemName} not found.`);
//               }

//             case 'gr':
//               groupStyles = { ...groupStyles, ...pathStyles };
//               break;
//             default:
//               break;
//           }

//           if (shapeElement) {
//             groupContent += shapeElement;
//           }
//         });



//         if (groupContent) {
//           const groupMotionComponentCode = createMotionComponentCode(shape);
//           if (shape.it.length > 0) {

//             // let layerMaxrix = calculateLayerTransformMatrix(layer)
//             let fillColor = "inherit"
//             let fillOpacity = "inherit"
//             // console.log("layer", layerMaxrix)
//             // console.log("shape", shapeMatrixVals)

//             if (layer.ef) {

//               layer.ef.forEach((effect) => {
//                 if (effect.ty === 21) {
//                   effect.ef.forEach((fillParam) => {
//                     //Apply fill to parent if not animated
//                     // console.log(fillParam)
//                     if (fillParam.mn === "ADBE Fill-0002" && !fillParam.v.a) {
//                       fillColor = convertColor(fillParam.v.k)
//                     }
//                     if (fillParam.mn === "ADBE Fill-0005" && !fillParam.v.a) {
//                       fillOpacity = fillParam.v.k
//                     }
//                   })
//                 }
//               })
//             }
//             shape.it.forEach(item => {

//               // let shapeMatrixVals = calculateShapeTransformMatrix(shape)

//               if (item.ty === 'tr') {
//                 if (item.p.a || item.r.a || item.s.a || item.o.a) {
//                   reactCode += `${groupContent}`
//                   //Wrap If Animated
//                   // reactCode += `
//                   // <${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>
//                   //     ${groupContent}
//                   // </${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>`;
//                   // reactCode += `
//                   // <${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>
//                   //   <g key={${shapeIndex}}  transform='matrix(${shapeMatrixVals[0]}, ${shapeMatrixVals[1]}, ${shapeMatrixVals[2]}, ${shapeMatrixVals[3]}, ${shapeMatrixVals[4]}, ${shapeMatrixVals[5]})'>
//                   //     ${groupContent}
//                   //   </g>
//                   // </${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>`;
//                 } else {

//                   if (layer.shapes.length > 1) {

//                     alert("no")
//                     // reactCode += `
//                     // <g key={${shapeIndex}} id="${shape.ln}" fill="${fillColor}" fill-opacity="${fillOpacity}" transform='matrix(${shapeMatrixVals[0]}, ${shapeMatrixVals[1]}, ${shapeMatrixVals[2]}, ${shapeMatrixVals[3]}, ${shapeMatrixVals[4]}, ${shapeMatrixVals[5]})' style={{width:'inherit', height:'inherit', transformOrigin: '${calculateTransformOrigin(layer, shape)[0]} ${calculateTransformOrigin(layer, shape)[1]}'}} >
//                     //   ${groupContent}
//                     // </g>`
//                     reactCode += `<g>
//                       ${groupContent}
//                     </g>`

//                   } else {

//                     reactCode += groupContent
//                     // reactCode += `
//                     //   <g key={${shapeIndex}} id="${shape.ln}" fill="${fillColor}" fill-opacity="${fillOpacity}" transform='matrix(${shapeMatrixVals[0]}, ${shapeMatrixVals[1]}, ${shapeMatrixVals[2]}, ${shapeMatrixVals[3]}, ${shapeMatrixVals[4]}, ${shapeMatrixVals[5]})' style={{width:'inherit', height:'inherit', transformOrigin: '${calculateTransformOrigin(layer, shape)[0]} ${calculateTransformOrigin(layer, shape)[1]}'}} >
//                     //     ${groupContent}
//                     //   </g>`


//                     // reactCode = reactCode.replace('style={{top:"123456789px"}}', `style={{transformOrigin: '${calculateTransformOrigin(layer, shape)[0]} ${calculateTransformOrigin(layer, shape)[1]}'}}`);
//                   }
//                 }

//               }
//             });

//           }
//           if (groupMotionComponentCode.length > 0) {
//             reactCode += `</${capitalizeFirstLetter(cleanName(shape.nm + "_" + layer.nm + "_" + shapeIndex))}>`;
//           }
//         }
//       }
//     });

//     if (motionComponentCode.length > 0) {
//       reactCode += `</${motionComponentName}>`;
//     }
//     reactCode += '</g>'
//   }


//   return reactCode;
// }


function convertSolidLayerToReact(layer, layerIndex, motionComponents) {
  let reactCode = '';
  const initPos = layer.ks.p.a ? layer.ks.p.k[0].s : layer.ks.p.k;

  if (layer.ty === 1) { // Check if it's a solid layer
    const motionComponentCode = createMotionComponentCode(layer);
    const motionComponentName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let wrapperClassName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let matrixVals = calculateLayerTransformMatrix(layer);
    reactCode += `<g id="${layer.ln}" transform='matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})'>`;
    if (motionComponentCode.length > 0) {
      const unquotedMotionComponentCode = motionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
      const unquotedInfinity = unquotedMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
      motionComponents.add(`const ${motionComponentName} = createMotionComponent(${unquotedInfinity});`);


      reactCode += `<${motionComponentName}>`;
    }

    const solidColor = layer.sc || "#000000";
    const solidWidth = layer.sw || 100;
    const solidHeight = layer.sh || 100;

    let fillColor = solidColor;
    let fillOpacity = 1;
    let hasFillEffect = false;
    let isFillAnimated = false;

    if (layer.ef) {
      layer.ef.forEach((effect) => {
        if (effect.ty === 21) {
          hasFillEffect = true;
          effect.ef.forEach((fillParam) => {
            if (fillParam.mn === "ADBE Fill-0002") {
              if (fillParam.v.a) {
                isFillAnimated = true;
              } else {
                fillColor = convertColor(fillParam.v.k);
              }
            }
            if (fillParam.mn === "ADBE Fill-0005") {
              if (fillParam.v.a) {
                isFillAnimated = true;
              } else {
                fillOpacity = fillParam.v.k;
              }
            }
          });
        }
      });
    }

    const rectFill = isFillAnimated ? "inherit" : fillColor;
    const groupFill = hasFillEffect ? (isFillAnimated ? "inherit" : fillColor) : "inherit";
    const groupFillOpacity = hasFillEffect ? (isFillAnimated ? "inherit" : fillOpacity) : "inherit";
    const [transformOriginX, transformOriginY] = calculateTransformOriginSolidLayer(layer);
    reactCode += `
      <g fill="${groupFill}" fill-opacity="${groupFillOpacity}"  style={{ transformOrigin: '${transformOriginX} ${transformOriginY}' }}>
        <rect
          id="${layer.ln}"
          fill="${rectFill}"
          width="${solidWidth}"
          height="${solidHeight}"
        
        />
      </g>`;

    if (motionComponentCode.length > 0) {
      reactCode += `</${motionComponentName}>`;
    }
    reactCode += '</g>';
  }

  return reactCode;
}

function calculateTransformOriginSolidLayer(layer) {
  let layerMatrix = calculateLayerTransformMatrix(layer);
  let transformOriginX = '50%', transformOriginY = '50%'; // Initialize to 50%

  // Check if position keyframes exist
  if (layer.ks?.p?.k) {
    // Check if position is animated
    if (layer.ks?.p?.a) {
      let posX = layer.ks.p.k[0].s[0];
      let posY = layer.ks.p.k[0].s[1];
      let toX = -(layerMatrix[4] - posX);
      let toY = -(layerMatrix[5] - posY);
      let scaleXAdjustment = toX / layerMatrix[0];
      let scaleYAdjustment = toY / layerMatrix[3];
      transformOriginX = round(scaleXAdjustment) + 'px';
      transformOriginY = round(scaleYAdjustment) + 'px';
    } else {
      let posX = layer.ks.p.k[0];
      let posY = layer.ks.p.k[1];
      let toX = -(layerMatrix[4] - posX);
      let toY = -(layerMatrix[5] - posY);
      let scaleXAdjustment = toX / layerMatrix[0];
      let scaleYAdjustment = toY / layerMatrix[3];
      transformOriginX = round(scaleXAdjustment) + 'px';
      transformOriginY = round(scaleYAdjustment) + 'px';
    }
  }

  return [transformOriginX, transformOriginY];
}



function convertImageLayerToReact(layer, assetMap, motionComponents, assets) {
  let reactCode = '';
  const initPos = layer.ks.p.a ? layer.ks.p.k[0].s : layer.ks.p.k;

  if (layer.ty === 2) { // Check if it's an image layer
    const motionComponentCode = createMotionComponentCode(layer);
    const motionComponentName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let wrapperClassName = capitalizeFirstLetter(cleanName(layer.nm + "_" + layer.ind));
    let matrixVals = calculateLayerTransformMatrix(layer);

    // Find the asset source for the image using the assetMap
    const imageSrc = assetMap[layer.refId] ? assetMap[layer.refId] : '';

    // Find the asset details from the assets argument
    const asset = assets.find(a => a.id === layer.refId);
    const assetWidth = asset ? asset.w : layer.sw;
    const assetHeight = asset ? asset.h : layer.sh;
    reactCode += `<g id="${layer.ln}" transform='matrix(${matrixVals[0]}, ${matrixVals[1]}, ${matrixVals[2]}, ${matrixVals[3]}, ${matrixVals[4]}, ${matrixVals[5]})'>`;
    if (motionComponentCode.length > 0) {
      const unquotedMotionComponentCode = motionComponentCode.replace(/"motionTokens\.[^"]+"/g, match => match.slice(1, -1));
      const unquotedInfinity = unquotedMotionComponentCode.replace(/"Infinity"/g, 'Infinity');
      motionComponents.add(`const ${motionComponentName} = createMotionComponent(${unquotedInfinity});`);


      reactCode += `<${motionComponentName}>`;
    }

    const [transformOriginX, transformOriginY] = calculateTransformOriginSolidLayer(layer);

    reactCode += `
      <image
        id="${layer.ln}"
        href={${imageSrc}}
        width="${assetWidth}px"
        height="${assetHeight}px"
        style={{transformBox:'view-box',transformOrigin: '${transformOriginX} ${transformOriginY}' }}
      />`;

    if (motionComponentCode.length > 0) {
      reactCode += `</${motionComponentName}>`;
    }
    reactCode += '</g>';
  }

  return reactCode;
}





function createShapeMotionComponentCode(shapeProps) {
  const keyframes = [];

  const addKeyframeWithOffset1 = (kfArray) => {
    const lastKeyframe = kfArray[kfArray.length - 1];
    if (lastKeyframe && lastKeyframe.offset !== 1) {
      kfArray.push({
        ...lastKeyframe,
        offset: 1,
      });
    }
  };

  const createPositionKeyframes = () => {
    if (shapeProps.p?.a) {
      const initialKeyframe = shapeProps.p.k[0].s;
      const keyframes = [{
        transform: `translate(${0}px, ${0}px)`,
        offset: 0,
        easing: 'linear',
        composite: 'accumulate', // Example composite setting
      }];
      keyframes.push(...shapeProps.p.k.map((kf) => ({
        transform: `translate(${kf.s[0] - initialKeyframe[0]}px, ${kf.s[1] - initialKeyframe[1]}px)`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'accumulate', // Example composite setting
      })));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createScaleKeyframes = () => {
    if (shapeProps.s?.a) {
      const initialKeyframe = shapeProps.s.k[0].s;
      const initialScaleX = initialKeyframe[0] / 100;
      const initialScaleY = initialKeyframe[1] / 100;
      const keyframes = [{
        transform: `scale(1, 1)`,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      }];
      keyframes.push(...shapeProps.s.k.map((kf) => {
        const scaleX = (kf.s[0] / 100) / initialScaleX;
        const scaleY = (kf.s[1] / 100) / initialScaleY;
        return {
          transform: `scale(${scaleX}, ${scaleY})`,
          offset: timeToKeyframeOffset(kf),
          easing: calcMotionCurve(kf),
          composite: 'add',
        };
      }));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createRotationKeyframes = () => {
    if (shapeProps.r?.a) {
      const initialKeyframe = shapeProps.r.k[0].s;
      const keyframes = [{
        transform: `rotate(${initialKeyframe}deg)`,
        offset: 0,
        easing: 'linear',
        composite: 'add', // Example composite setting
      }];
      keyframes.push(...shapeProps.r.k.map((kf) => ({
        transform: `rotate(${kf.s}deg)`,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
        composite: 'add', // Example composite setting
      })));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  const createOpacityKeyframes = () => {
    if (shapeProps.o?.a) {
      const initialKeyframe = shapeProps.o.k[0].s;
      const keyframes = [{
        opacity: initialKeyframe / 100,
        offset: 0,
        easing: 'linear',
        composite: 'add',
      }];
      keyframes.push(...shapeProps.o.k.map((kf) => ({
        opacity: kf.s / 100,
        offset: timeToKeyframeOffset(kf),
        easing: calcMotionCurve(kf),
      })));
      addKeyframeWithOffset1(keyframes);
      return {
        keyframes,
        duration: compDurationMilliseconds,
        iterations: 'Infinity',
      };
    }
    return null;
  };

  if (shapeProps.ty === 'tr') {
    const positionTimeline = createPositionKeyframes();
    if (positionTimeline) keyframes.push(positionTimeline);

    const scaleTimeline = createScaleKeyframes();
    if (scaleTimeline) keyframes.push(scaleTimeline);

    const rotateTimeline = createRotationKeyframes();
    if (rotateTimeline) keyframes.push(rotateTimeline);

    const opacityTimeline = createOpacityKeyframes();
    if (opacityTimeline) keyframes.push(opacityTimeline);
  }

  if (shapeProps.it) {
    shapeProps.it.forEach((subItem) => {
      const subItemKeyframes = createShapeMotionComponentCode(subItem);
      if (subItemKeyframes) {
        keyframes.push(...JSON.parse(subItemKeyframes));
      }
    });
  }

  return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
}


// function createShapeMotionComponentCode(shapeProps) {
//   console.log("her0", shapeProps)
//   const keyframes = [];

//   const addKeyframeWithOffset1 = (kfArray) => {
//     const lastKeyframe = kfArray[kfArray.length - 1];
//     if (lastKeyframe && lastKeyframe.offset !== 1) {
//       kfArray.push({
//         ...lastKeyframe,
//         offset: 1,
//       });
//     }
//   };

//   const createKeyframes = (property, transformFunc) => {
//     if (shapeProps[property]?.a) {
//       const initialKeyframe = shapeProps[property].k[0].s;
//       const keyframes = shapeProps[property].k.map((kf) => ({
//         transform: transformFunc(kf.s, initialKeyframe),
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//         composite: 'add',
//       }));
//       addKeyframeWithOffset1(keyframes);
//       return {
//         keyframes,
//         duration: compDurationMilliseconds,
//         iterations: 'Infinity',
//       };
//     }
//     return null;
//   };

//   if (shapeProps.ty === 'tr') {
//     const positionTimeline = createKeyframes('p', (s, initial) => `translate(${s[0] - initial[0]}px, ${s[1] - initial[1]}px)`);
//     if (positionTimeline) keyframes.push(positionTimeline);

//     const scaleTimeline = createKeyframes('s', (s, initial) => `scale(${s[0] / initial[0]}, ${s[1] / initial[1]})`);
//     if (scaleTimeline) keyframes.push(scaleTimeline);

//     const rotateTimeline = createKeyframes('r', (s, initial) => `rotate(${s - initial}deg)`);
//     if (rotateTimeline) keyframes.push(rotateTimeline);

//     if (shapeProps.o?.a) {
//       const opacityKeyframes = shapeProps.o.k.map((kf) => ({
//         opacity: kf.s / 100,
//         offset: timeToKeyframeOffset(kf),
//         easing: calcMotionCurve(kf),
//       }));
//       addKeyframeWithOffset1(opacityKeyframes);
//       if (opacityKeyframes.length > 0) {
//         keyframes.push({
//           keyframes: opacityKeyframes,
//           duration: compDurationMilliseconds,
//           iterations: 'Infinity',
//         });
//       }
//     }
//   }

//   if (shapeProps.it) {
//     shapeProps.it.forEach((subItem) => {
//       const subItemKeyframes = createShapeMotionComponentCode(subItem);
//       if (subItemKeyframes) {
//         keyframes.push(...JSON.parse(subItemKeyframes));
//       }
//     });
//   }

//   return keyframes.length > 0 ? JSON.stringify(keyframes, null, 2) : '';
// }




function convertShapePathData(shapePathData) {
  if (!shapePathData.v || !Array.isArray(shapePathData.v) || shapePathData.v.length === 0) {
    console.error('Invalid or missing vertices in shape path data');
    return '';
  }
  if (!shapePathData.i || !Array.isArray(shapePathData.i) || shapePathData.i.length !== shapePathData.v.length) {
    console.error('Invalid or missing in tangents in shape path data');
    return '';
  }
  if (!shapePathData.o || !Array.isArray(shapePathData.o) || shapePathData.o.length !== shapePathData.v.length) {
    console.error('Invalid or missing out tangents in shape path data');
    return '';
  }

  let pathData = `M${shapePathData.v[0][0]},${shapePathData.v[0][1]}`;

  for (let i = 1; i < shapePathData.v.length; i++) {
    const currentPoint = shapePathData.v[i];
    const previousPoint = shapePathData.v[i - 1];
    const inTangent = shapePathData.i[i];
    const outTangent = shapePathData.o[i - 1];

    pathData += ` C${(previousPoint[0] + outTangent[0]).toFixed(6)},${(previousPoint[1] + outTangent[1]).toFixed(6)} ${(currentPoint[0] + inTangent[0]).toFixed(6)},${(currentPoint[1] + inTangent[1]).toFixed(6)} ${currentPoint[0].toFixed(6)},${currentPoint[1].toFixed(6)}`;
  }

  if (shapePathData.c) {
    const firstPoint = shapePathData.v[0];
    const lastOutTangent = shapePathData.o[shapePathData.o.length - 1];
    const firstInTangent = shapePathData.i[0];
    pathData += ` C${(shapePathData.v[shapePathData.v.length - 1][0] + lastOutTangent[0]).toFixed(6)},${(shapePathData.v[shapePathData.v.length - 1][1] + lastOutTangent[1]).toFixed(6)} ${(firstPoint[0] + firstInTangent[0]).toFixed(6)},${(firstPoint[1] + firstInTangent[1]).toFixed(6)} ${firstPoint[0].toFixed(6)},${firstPoint[1].toFixed(6)} Z`;
  }

  return pathData;
}

function createPathElement(shapeProps, pathStyles, key) {
  if (Array.isArray(shapeProps.ks.k)) {
    return shapeProps.ks.k.map((prop, idx) => {
      const pathData = convertShapePathData(prop.s[0]);
      return `<path key={${key}} d="${pathData}" fill="${pathStyles.fill}" stroke="${pathStyles.stroke}" stroke-width="${pathStyles.strokeWidth}" stroke-opacity="${1}"/>`;
    }).join('');
  } else {
    const pathData = convertShapePathData(shapeProps.ks.k);
    return `<path key={${key}} d="${pathData}" fill="${pathStyles.fill}" stroke="${pathStyles.stroke}" stroke-width="${pathStyles.strokeWidth}" stroke-opacity="${1}"/>`;
  }
}

function createCombinedPathElement(shapePropsArray, pathStyles, key) {
  const combinedPathData = shapePropsArray.map(shapeProps => {
    if (Array.isArray(shapeProps.ks.k)) {
      return shapeProps.ks.k.map(prop => convertShapePathData(prop.s[0])).join(' ');
    } else {
      return convertShapePathData(shapeProps.ks.k);
    }
  }).join(' ');

  return `<path key={${key}} d="${combinedPathData}" fill="${pathStyles.fill}" stroke="${pathStyles.stroke}" stroke-width="${pathStyles.strokeWidth}" stroke-opacity="${1}"/>`;
}












function styleStringToObject(styles) {
  const styleObject = {};
  Object.entries(styles).forEach(([key, value]) => {
    styleObject[camelCase(key)] = value;
  });
  return styleObject;
}


function camelCase(str) {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}


function convertColor(colorArray) {
  if (!colorArray || colorArray.length < 3) return undefined;
  const [r, g, b, a = 1] = colorArray.map(c => Math.round(c * 255)); // Default `a` to 1 if not provided
  return `rgba(${r},${g},${b},${a})`;
}


function cleanName(name) {
  return name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function styleToString(styles) {
  return Object.entries(styles).map(([key, value]) => `${key}:${value};`).join(' ');
}

function calculateGroupTransformMatrix(layer, compWidth, compHeight, frame = 0) {
  // Helper function to get the value at a specific frame for animated properties
  function getValueAtFrame(keyframes, frame) {
    if (!keyframes.a) {
      return keyframes.k;
    }
    let value = keyframes.k[0].s;
    for (let i = 0; i < keyframes.k.length; i++) {
      if (frame < keyframes.k[i].t) {
        break;
      }
      value = keyframes.k[i].s;
    }
    return value;
  }

  // Extract the position and anchor point values from the layer
  const anchor = getValueAtFrame(layer.ks.a, frame);
  const position = getValueAtFrame(layer.ks.p, frame);

  const anchorX = anchor[0];
  const anchorY = anchor[1];
  const posX = position[0];
  const posY = position[1];

  // Calculate the transform matrix using position values
  const transformMatrix = `matrix(1, 0, 0, 1, ${posX - anchorX}, ${posY - anchorY})`;

  return {
    transform: transformMatrix
  };
}



function calculateShapeTransformMatrix(shape, frame = 0) {
  // Helper function to get the value at a specific frame for animated properties
  function getValueAtFrame(keyframes, frame) {
    if (!keyframes.a) {
      return keyframes.k;
    }
    let value = keyframes.k[0].s;
    for (let i = 0; i < keyframes.k.length; i++) {
      if (frame < keyframes.k[i].t) {
        break;
      }
      value = keyframes.k[i].s;
    }
    return value;
  }

  // Extract the transform properties from the shape
  let position, anchor, scale, rotation;
  if (shape.it) {
    shape.it.forEach(item => {
      if (item.ty === 'tr') {
        position = getValueAtFrame(item.p, frame);
        anchor = getValueAtFrame(item.a, frame);
        scale = getValueAtFrame(item.s, frame);
        rotation = getValueAtFrame(item.r, frame);
      }
    });

  }

  const posX = position ? position[0] : 0;
  const posY = position ? position[1] : 0;
  const anchorX = anchor ? anchor[0] : 0;
  const anchorY = anchor ? anchor[1] : 0;
  const scaleX = (scale ? scale[0] : 100) / 100;
  const scaleY = (scale ? scale[1] : 100) / 100;
  const rotate = (rotation ? rotation[0] : 0) * (Math.PI / 180); // Convert degrees to radians

  // Calculate the transform matrix
  const cosRotate = Math.cos(rotate);
  const sinRotate = Math.sin(rotate);

  const a = scaleX * cosRotate;
  const b = scaleX * sinRotate;
  const c = -scaleY * sinRotate;
  const d = scaleY * cosRotate;

  const e = posX - (anchorX * a + anchorY * c);
  const f = posY - (anchorX * b + anchorY * d);

  // return {
  //   transform: `matrix(${a}, ${b}, ${c}, ${d}, ${e}, ${f})`
  // };
  return [a, b, c, d, e, f];
}










// function lottieShapeToPath(shape, pathStyles, shapeValue, layer) {
//   let pathData = '';
//   console.log(shape);

//   const getValue = (property) => {
//     if (property.a === 1) {
//       return property.k[0].s; // Use the first keyframe's start value for simplicity
//     } else {
//       return property.k;
//     }
//   };

//   switch (shape.ty) {
//     case 'rc': // Rectangle
//       const rp = getValue(shape.p);
//       const rs = getValue(shape.s);
//       const rr = getValue(shape.r);
//       const rx = rp[0] - rs[0] / 2;
//       const ry = rp[1] - rs[1] / 2;
//       const rwidth = rs[0];
//       const rheight = rs[1];
//       const rrx = rr;

//       if (rrx === 0) {
//         pathData = `M${rx},${ry} H${rx + rwidth} V${ry + rheight} H${rx} Z`;
//       } else {
//         pathData = `
//           M${rx + rrx},${ry}
//           H${rx + rwidth - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth},${ry + rrx}
//           V${ry + rheight - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth - rrx},${ry + rheight}
//           H${rx + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx},${ry + rheight - rrx}
//           V${ry + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rrx},${ry}
//           Z
//         `.trim().replace(/\s+/g, ' ');
//       }
//       break;

//     case 'el': // Ellipse
//       const ep = getValue(shape.p);
//       const es = getValue(shape.s);
//       const ecx = ep[0];
//       const ecy = ep[1];
//       const erx = es[0] / 2;
//       const ery = es[1] / 2;

//       pathData = `
//         M${ecx - erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx + erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx - erx},${ecy}
//       `.trim().replace(/\s+/g, ' ');
//       break;

//     // Add more cases as needed for other shape types

//     default:
//       throw new Error(`Unsupported shape type: ${shape.ty}`);
//   }

//   const attributes = {
//     d: pathData,
//     fill: pathStyles.fill,
//     stroke: pathStyles.stroke,
//     'stroke-width': pathStyles.strokeWidth,
//     'stroke-opacity': 1,
//   };

//   if (layer.shapes?.length > 1) {
//     attributes.transform = calculateShapeTransformMatrix(shapeValue).transform;
//   }

//   const attributeString = Object.entries(attributes)
//     .filter(([_, value]) => value !== undefined)
//     .map(([key, value]) => `${key}="${value}"`)
//     .join(' ');

//   return `<path ${attributeString} />`;
// }


// function lottieShapeToPath(shape, pathStyles, shapeValue, layer) {
//   let pathData = '';
//   console.log(shape);

//   switch (shape.ty) {
//     case 'rc': // Rectangle
//       const { p: rp, s: rs, r: rr } = shape;
//       const rx = rp.k[0] - rs.k[0] / 2;
//       const ry = rp.k[1] - rs.k[1] / 2;
//       const rwidth = rs.k[0];
//       const rheight = rs.k[1];
//       const rrx = rr.k;

//       if (rrx === 0) {
//         pathData = `M${rx},${ry} H${rx + rwidth} V${ry + rheight} H${rx} Z`;
//       } else {
//         pathData = `
//           M${rx + rrx},${ry}
//           H${rx + rwidth - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth},${ry + rrx}
//           V${ry + rheight - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth - rrx},${ry + rheight}
//           H${rx + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx},${ry + rheight - rrx}
//           V${ry + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rrx},${ry}
//           Z
//         `.trim().replace(/\s+/g, ' ');
//       }
//       break;

//     case 'el': // Ellipse
//       const { p: ep, s: es } = shape;
//       const ecx = ep.k[0];
//       const ecy = ep.k[1];
//       const erx = es.k[0] / 2;
//       const ery = es.k[1] / 2;

//       pathData = `
//         M${ecx - erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx + erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx - erx},${ecy}
//       `.trim().replace(/\s+/g, ' ');
//       break;

//     // Add more cases as needed for other shape types

//     default:
//       throw new Error(`Unsupported shape type: ${shape.ty}`);
//   }

//   const attributes = {
//     d: pathData,
//     fill: pathStyles.fill,
//     stroke: pathStyles.stroke,
//     'stroke-width': pathStyles.strokeWidth,
//     'stroke-opacity': 1,
//   };

//   if (layer.shapes?.length > 1) {
//     attributes.transform = calculateShapeTransformMatrix(shapeValue).transform;
//   }

//   const attributeString = Object.entries(attributes)
//     .filter(([_, value]) => value !== undefined)
//     .map(([key, value]) => `${key}="${value}"`)
//     .join(' ');

//   return `<path ${attributeString} />`;
// }

// function lottieShapeToPath(shape, pathStyles, shapeValue, layer) {

//   let pathData = '';
//   const getFirstKeyframe = (property) => {
//     return property.a ? property.k[0].s : property.k;
//   };

//   switch (shape.ty) {
//     case 'rc': // Rectangle
//       const { p: rp, s: rs, r: rr } = shape;
//       const size = getFirstKeyframe(rs);
//       const rx = rp.k[0] - size[0] / 2;
//       const ry = rp.k[1] - size[1] / 2;
//       const rwidth = size[0];
//       const rheight = size[1];
//       const rrx = rr.a ? rr.k[0].s : rr.k;

//       if (rrx === 0) {
//         pathData = `M${rx},${ry} H${rx + rwidth} V${ry + rheight} H${rx} Z`;
//       } else {
//         pathData = `
//           M${rx + rrx},${ry}
//           H${rx + rwidth - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth},${ry + rrx}
//           V${ry + rheight - rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rwidth - rrx},${ry + rheight}
//           H${rx + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx},${ry + rheight - rrx}
//           V${ry + rrx}
//           A${rrx},${rrx} 0 0 1 ${rx + rrx},${ry}
//           Z
//         `.trim().replace(/\s+/g, ' ');
//       }
//       break;

//     case 'el': // Ellipse
//       const { p: ep, s: es } = shape;
//       const ellipseSize = getFirstKeyframe(es);
//       const ecx = ep.k[0];
//       const ecy = ep.k[1];
//       const erx = ellipseSize[0] / 2;
//       const ery = ellipseSize[1] / 2;

//       pathData = `
//         M${ecx - erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx + erx},${ecy}
//         A${erx},${ery} 0 1,0 ${ecx - erx},${ecy}
//       `.trim().replace(/\s+/g, ' ');
//       break;

//     // Add more cases as needed for other shape types

//     default:
//       throw new Error(`Unsupported shape type: ${shape.ty}`);
//   }

//   const attributes = {
//     d: pathData,
//     fill: pathStyles.fill !== undefined ? pathStyles.fill : undefined,
//     stroke: pathStyles.stroke !== undefined ? pathStyles.stroke : undefined,
//     'stroke-width': pathStyles.strokeWidth !== undefined ? pathStyles.strokeWidth : undefined,
//     'stroke-opacity': pathStyles.strokeOpacity !== undefined ? pathStyles.strokeOpacity : undefined,
//   };

//   if (layer.shapes?.length > 1) {
//     attributes.transform = calculateShapeTransformMatrix(shapeValue).transform;
//   }

//   // Remove undefined attributes
//   const attributeString = Object.entries(attributes)
//     .filter(([_, value]) => value !== undefined)
//     .map(([key, value]) => `${key}="${value}"`)
//     .join(' ');

//   return `<path ${attributeString} />`;
// }

function lottieShapeToPath(shape, pathStyles, shapeValue, layer) {

  let pathData = '';
  const getFirstKeyframe = (property) => {
    return property.a ? property.k[0].s : property.k;
  };

  switch (shape.ty) {
    case 'rc': // Rectangle
      const { p: rp, s: rs, r: rr } = shape;
      const size = getFirstKeyframe(rs);
      const rx = rp.k[0] - size[0] / 2;
      const ry = rp.k[1] - size[1] / 2;
      const rwidth = size[0];
      const rheight = size[1];
      const rrx = getFirstKeyframe(rr);

      if (rrx === 0) {
        pathData = `M${rx},${ry} H${rx + rwidth} V${ry + rheight} H${rx} Z`;
      } else {
        const cornerRadius = Math.min(rrx, rwidth / 2, rheight / 2); // Ensure the radius is not larger than half the width/height
        pathData = `
          M${rx + cornerRadius},${ry}
          H${rx + rwidth - cornerRadius}
          Q${rx + rwidth},${ry} ${rx + rwidth},${ry + cornerRadius}
          V${ry + rheight - cornerRadius}
          Q${rx + rwidth},${ry + rheight} ${rx + rwidth - cornerRadius},${ry + rheight}
          H${rx + cornerRadius}
          Q${rx},${ry + rheight} ${rx},${ry + rheight - cornerRadius}
          V${ry + cornerRadius}
          Q${rx},${ry} ${rx + cornerRadius},${ry}
          Z
        `.trim().replace(/\s+/g, ' ');
      }
      break;

    case 'el': // Ellipse
      const { p: ep, s: es } = shape;
      const ellipseSize = getFirstKeyframe(es);
      const ecx = ep.k[0];
      const ecy = ep.k[1];
      const erx = ellipseSize[0] / 2;
      const ery = ellipseSize[1] / 2;

      pathData = `
        M${ecx - erx},${ecy}
        A${erx},${ery} 0 1,0 ${ecx + erx},${ecy}
        A${erx},${ery} 0 1,0 ${ecx - erx},${ecy}
      `.trim().replace(/\s+/g, ' ');
      break;

    case 'sr': // Star

      const { p: sp, pt: spt, r: sr, ir: sir, or: sor } = shape;
      const starPoints = getFirstKeyframe(spt);
      const starOuterRadius = getFirstKeyframe(sor);
      const starInnerRadius = getFirstKeyframe(sir);
      const spx = sp.k[0];
      const spy = sp.k[1];

      pathData = '';
      for (let i = 0; i < starPoints * 2; i++) {
        const angle = Math.PI * i / starPoints;
        const radius = i % 2 === 0 ? starOuterRadius : starInnerRadius;
        const x = spx + Math.cos(angle - Math.PI / 2) * radius;
        const y = spy + Math.sin(angle - Math.PI / 2) * radius;
        pathData += `${i === 0 ? 'M' : 'L'}${x},${y}`;
      }
      pathData += ' Z';
      break;

    case 'pol': // Polygon
      const { p: pp, pt: ppt, r: pr } = shape;
      const polyPoints = getFirstKeyframe(ppt);
      const polyRadius = getFirstKeyframe(pr);
      const ppx = pp.k[0];
      const ppy = pp.k[1];

      pathData = '';
      for (let i = 0; i < polyPoints; i++) {
        const angle = Math.PI * 2 * i / polyPoints;
        const x = ppx + Math.cos(angle - Math.PI / 2) * polyRadius;
        const y = ppy + Math.sin(angle - Math.PI / 2) * polyRadius;
        pathData += `${i === 0 ? 'M' : 'L'}${x},${y}`;
      }
      pathData += ' Z';
      break;

    default:
      throw new Error(`Unsupported shape type: ${shape.ty}`);
  }

  const attributes = {
    d: pathData,
    fill: pathStyles.fill !== undefined ? pathStyles.fill : undefined,
    stroke: pathStyles.stroke !== undefined ? pathStyles.stroke : undefined,
    'stroke-width': pathStyles.strokeWidth !== undefined ? pathStyles.strokeWidth : undefined,
    'stroke-opacity': pathStyles.strokeOpacity !== undefined ? pathStyles.strokeOpacity : undefined,
  };

  if (layer.shapes?.length > 1) {
    let shapeMatrixVals = calculateShapeTransformMatrix(shapeValue)
    attributes.transform = `matrix(${shapeMatrixVals[0]}, ${shapeMatrixVals[1]}, ${shapeMatrixVals[2]}, ${shapeMatrixVals[3]}, ${shapeMatrixVals[4]}, ${shapeMatrixVals[5]})`
  }

  const attributeString = Object.entries(attributes)
    .filter(([_, value]) => value !== undefined && value !== 'undefined')
    .map(([key, value]) => `${key}="${value}"`)
    .join(' ');

  return `<path ${attributeString} />`;
}




//works fine 2d version
function calculateLayerTransformMatrix(layer, frame = 0) {
  // Helper function to get the value at a specific frame for animated properties
  function getValueAtFrame(keyframes, frame) {
    if (!keyframes.a) {
      return keyframes.k;
    }
    let value = keyframes.k[0].s;
    for (let i = 0; i < keyframes.k.length; i++) {
      if (frame < keyframes.k[i].t) {
        break;
      }
      value = keyframes.k[i].s;
    }
    return value;
  }

  // Extract the transform properties from the layer
  let position = layer.ks.p ? getValueAtFrame(layer.ks.p, frame) : [0, 0];
  let anchor = layer.ks.a ? getValueAtFrame(layer.ks.a, frame) : [0, 0];
  let scale = layer.ks.s ? getValueAtFrame(layer.ks.s, frame) : [100, 100];
  let rotation = layer.ks.r ? getValueAtFrame(layer.ks.r, frame) : 0;

  const posX = position[0];
  const posY = position[1];
  const anchorX = anchor[0];
  const anchorY = anchor[1];
  const scaleX = scale[0] / 100;
  const scaleY = scale[1] / 100;
  const angle = rotation * (Math.PI / 180); // Convert degrees to radians

  const cos = Math.cos(angle);
  const sin = Math.sin(angle);

  // Transformation matrix components
  const a = cos * scaleX;
  const b = sin * scaleX;
  const c = -sin * scaleY;
  const d = cos * scaleY;

  // Translation including anchor point adjustment
  const e = posX - (anchorX * a + anchorY * c);
  const f = posY - (anchorX * b + anchorY * d);


  return [a, b, c, d, e, f];
}






