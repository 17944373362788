import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { collection, setDoc, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import './Header.css';

import OpenInReactSandbox from '../CodeSandbox/OpenInReactSandbox';
import OpenInHTMLSandbox from '../CodeSandbox/OpenInHTMLSandbox';

import cleanName from '../HelperFunctions/cleanNames';
import shareIcon from '../Images/Icons/share.svg';
import attachmentIcon from '../Images/Icons/attachments.svg';
import shareIconDark from '../Images/Icons/shareDark.svg';
import layoutIcon from '../Images/Icons/layout.svg';
import notesIcon from '../Images/Icons/notes.svg';
import lockIcon from '../Images/Icons/lock.svg';
import eye_on from '../Images/Icons/eye_on.svg';
import eye_off from '../Images/Icons/eye_off.svg';
import doubleCheck from '../Images/Icons/doubleCheck.svg';
import publicIcon from '../Images/Icons/public.svg';
import privateIcon from '../Images/Icons/private.svg';
import backIcon from '../Images/Icons/back.svg'
import dataIcon from '../Images/Icons/data.svg';
import downloadIcon from '../Images/Icons/download.svg';


function Header(props) {
   
    const [showNotes, setShowNotes] = useState(false);
    const [publicShareLink, setPublicShareLink] = useState("");
    const [privateShareLink, setPrivateShareLink] = useState("");
    const [linkType, setLinkType] = useState(null);
    const [linkGenStep, setLinkGenStep] = useState(1);
    const [password, setSetPassword] = useState(null);
    const [showAttachments, setShowAttachments] = useState(false);
    const [copyText, setCopyText] = useState("Copy");
    const [notes, setNotes] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [requiresPassword, setRequiresPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [shareLinkType, setShareLinkType] = useState(null)
    const [showLinkGenerationModal, setShowLinkGenerationModal] = useState(false);


    useEffect(() => {
        setNotes(props.notes);
    }, [props.notes]);

    // useEffect(() => {
    //     console.log('props', props)
    // }, [props]);


    const createShareLink = async (type, pwd) => {
        setShareLinkType(type)
        // Create ShareLink Doc
        const docRef = await addDoc(collection(db, "sharelinks"), {
            type: type,
            spec_id: props.spec_id,
            created_by: auth.currentUser.email,
            uid: auth.currentUser.uid,
            active: true,
            date_created: new Date(),
            notes: notes,
            attachments: attachments,
            lottiePreviewBG: props.lottiePreviewBG,
            pwd: pwd
        });
        console.log("Link Ref written with ID: ", docRef.id);
        if (type === "private") {
            setPrivateShareLink("https://app.motionspec.io/dev/" + docRef.id)
        }
        if (type === "public") {
            setPublicShareLink("https://app.motionspec.io/dev/" + docRef.id)
        }

        const idAdded = await setDoc(doc(db, "sharelinks", docRef.id), {
            id: docRef.id
        }, { merge: true });

    };

    const updateSpec = async (action) => {
        let share_link = {}
        if (shareLinkType === "public") {
            share_link.type = "public"
            share_link.url = publicShareLink
        }
        if (shareLinkType === "private") {
            share_link.type = "private"
            share_link.url = privateShareLink
        }
        const updatedSepc = await setDoc(doc(db, "specs", props.spec_id), {
            notes: notes,
            lottiePreviewBG: props.lottiePreviewBG,
            attachments: attachments,
            share_link: share_link,

        }, { merge: true });
        if (action === "back") {
            window.location.href = "/"
        }
        if (action === "save") {
            setShowNotes(false)
        }
    }

    const handlePasswordUpdate = (e) => {

        setSetPassword(e.target.value)
    }


    const copyLinkToClipboard = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('Link copied to clipboard successfully.');
                setCopyText('Copied!')
                setTimeout(() => {
                    setCopyText('Copy')
                }, 3000);

            })
            .catch((error) => {
                console.error('Failed to copy link to clipboard:', error);
            });
    }

    const updateNotes = (e) => {
        setNotes(e.target.value)
    }

    const downloadLottieJson = (lottieData, fileName) => {
        try {
            // Check if lottieData is a valid object
            if (typeof lottieData !== 'object') {
                throw new Error('Invalid lottieData provided');
            }
    
            // Convert JSON object to string
            const json = JSON.stringify(lottieData, null, 2);
            console.log('JSON data:', json); // Logging the JSON data
    
            // Create a Blob from the JSON string
            const blob = new Blob([json], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            console.log('Blob URL:', url); // Logging the Blob URL
    
            // Create an anchor element and trigger the download
            const link = document.createElement('a');
            link.download = fileName;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            // Revoke the object URL to free up resources
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error in downloadLottieJson:', error); // Logging any errors
        }
    };
    


    return (
        <div id="main-header">
            {props.view_mode !== "dev" ?

                <div onClick={() => { updateSpec('back') }} style={{ display: 'grid', placeContent: 'center', marginLeft: '12px' }}>
                    <img src={backIcon} style={{ width: '24px' }} alt="back"></img>
                </div> : null
            }
            <div id="logo">MotionSpec.io</div>
            {props.lottieData ?
                <div id="header-comp-info-container">
                    <div className="header-comp-name">{props.lottieData.nm}</div>
                    <div style={{ display: 'flex', gap: '4px', placeContent: 'center' }}>
                        <div className="header-comp-subinfo">{props.lottieData.fr.toFixed(2) + " fps"}</div>
                        <div className="header-comp-subinfo">{props.lottieData.w + "px X " + props.lottieData.h + "px"}</div>
                    </div>

                </div>
                : null
            }

            <div className="right-controls">

                {/* <div className="header-text">Layout</div> */}
                {/* <div className="header-btn-secondary" onClick={props.switchLayout}>
                    <img className="header-icon" src={layoutIcon} alt="shareIcon"></img>
                    <div className="header-text">Layout</div>
                </div> */}
                <div className="header-btn-secondary" onClick={() => { setShowNotes(!showNotes); setShowLinkGenerationModal(false); setShowAttachments(false) }}>
                    <img className="header-icon" src={notesIcon} alt="shareIcon"></img>
                    <div className="header-text" style={{color:'black'}}>Notes</div>
                </div>
                <div id="notepadContainer" style={{ display: showNotes ? 'block' : 'none', transform: showNotes ? 'scale(1)' : 'scale(0)' }}>
                    <div className="create-link-modal-h2">Notes</div>
                    <textarea id="notepad" onChange={(e) => { updateNotes(e) }} value={notes} placeholder='Dev Notes' ></textarea>
                    {props.view_mode !== "dev" ?
                        <button className="primary-btn" style={{ position: 'relative', marginLeft: 'auto' }} onClick={() => { updateSpec('save') }}>Save</button> : null
                    }

                </div>

                <div className="header-btn-secondary" onClick={() => { setShowAttachments(!showAttachments); setShowLinkGenerationModal(false); setShowNotes(false) }}>
                    <img className="header-icon" src={attachmentIcon} alt="shareIcon"></img>
                    <div className="header-text">Attachments</div>
                </div>

                {showAttachments && props.lottieData ?
                    <div id="attachments-container">
                        <div className="attachment-container">
                            <img src={dataIcon} style={{ width: '20px' }} alt="jsonfile"></img>
                            <div style={{ marginLeft: '10px' }}>{cleanName(props.lottieData.nm + ".json")}</div>
                            <div className="dl-attachments-btn" onClick={() => { downloadLottieJson(props.lottieData, cleanName(props.lottieData.nm)) }}>
                                <img src={downloadIcon} style={{ width: '20px' }} alt="jsonfile"></img>
                            </div>
                        </div>

                    </div> : null
                }
                {/* {props.html ?
                    <OpenInHTMLSandbox css={props.css} html={props.html} lottieData={props.lottieData}></OpenInHTMLSandbox> : null
                } */}
                {/* {props.fluent ? 
                    <OpenStackBlitzButton code={props.fluent}/>:null
                } */}
                {/* {props.react ?
                    <OpenInReactSandbox css={props.css} react={props.react} lottieData={props.lottieData}></OpenInReactSandbox> : null
                } */}


                {props.view_mode !== "dev" ?
                    <div className="header-btn-primary" onClick={(() => { setShowLinkGenerationModal(!showLinkGenerationModal); setShowNotes(false); setShowAttachments(false); setLinkGenStep(1); setRequiresPassword(true); setShowPassword(false); setSetPassword(null); setCopyText("Copy") })}>
                        <img className="header-icon" src={shareIcon} alt="shareIcon"></img>
                        <div className="header-text">Generate Link</div>
                    </div> : null
                }
                {showLinkGenerationModal ?
                    <div className="create-link-modal">
                        {linkType === null ?

                            <React.Fragment>
                                <div className="create-link-modal-h2">Sharing Options</div>
                                <div style={{ display: 'grid', gridTemplateColumns: '160px 160px', gap: '0px' }}>
                                    <div className="share-link-type" onClick={() => { setLinkType('Public'); createShareLink('public', null) }}>
                                        <img style={{ width: '22px' }} src={publicIcon} alt="lockIcon"></img>
                                        <div className="share-link-type-text">Public Link</div>
                                    </div>

                                    <div className="share-link-type" onClick={() => { setLinkType('Private') }}>
                                        <img style={{ width: '22px' }} src={privateIcon} alt="lockIcon"></img>
                                        <div className="share-link-type-text">Private Link</div>
                                    </div>
                                    <p style={{ textAlign: 'center', width: '95%' }}>Viewable to everyone</p>
                                    <p style={{ textAlign: 'center', width: '95%' }}>Password Protected</p>
                                </div>
                            </React.Fragment> : null
                        }
                        {linkType === "Public" ?
                            <div>
                                <div className="create-link-modal-h2">{"Public Link"}</div>
                                <div className="share-link-container">
                                    <div className="share-icon-modal">
                                        <img style={{ width: '30px', opacity: .6 }} src={shareIconDark} alt="shareLink"></img>
                                    </div>
                                    <div className='share-link-preview'>
                                        {publicShareLink}
                                    </div>
                                    <div className="copy-btn" style={{ backgroundColor: copyText === "Copy" ? 'var(--primary-color)' : 'var(--success-color)' }} onClick={() => { copyLinkToClipboard(publicShareLink) }}>
                                        {copyText}
                                    </div>
                                </div>
                                <div id="link-share-btn-container">
                                    <button className="secondary-btn" onClick={() => { setLinkType(null) }}>Back</button>
                                    <button className="primary-btn" onClick={() => { setShowLinkGenerationModal(false) }}>Close</button>
                                </div>
                            </div> : null
                        }
                        {linkType === "Private" && linkGenStep === 1 ?
                            <div>
                                <div className="create-link-modal-h2">Set Link Password</div>
                                <div className="share-link-container">
                                    <div className="share-icon-modal">
                                        <img style={{ width: '26px', opacity: .4 }} src={lockIcon} alt="shareLink"></img>
                                    </div>
                                    <input className="set-password-input" onChange={handlePasswordUpdate} type={showPassword ? 'text' : 'password'}></input>
                                    <div className="eye-btn" style={{ opacity: .6 }} onClick={() => { setShowPassword(!showPassword) }}>
                                        {showPassword ?
                                            <img src={eye_on} style={{ width: '20px' }}></img> : <img src={eye_off} style={{ width: '20px' }}></img>
                                        }
                                    </div>
                                    <div className="dbl-check" style={{ opacity: .6 }} onClick={() => { setShowPassword(!showPassword) }}>
                                        {password !== null && password.length > 4 ?
                                            <img src={doubleCheck} style={{ width: '22px' }}></img> : null
                                        }
                                    </div>
                                </div>
                                <div id="link-share-btn-container">
                                    <button className="secondary-btn" onClick={() => { setLinkType(null) }}>Back</button>
                                    <button className="primary-btn" disabled={password !== null && password.length > 4 ? false : true} onClick={() => { setLinkGenStep(2); createShareLink('private', password) }}>Create Link</button>
                                </div>
                            </div> : null

                        }
                        {linkType === "Private" && linkGenStep === 2 ?
                            <div>
                                <div className="create-link-modal-h2">Private Link</div>
                                <div className="share-link-container" style={{ marginTop: '10px' }}>
                                    <div className="share-icon-modal">
                                        <img style={{ width: '30px', opacity: .6 }} src={shareIconDark} alt="shareLink"></img>
                                    </div>
                                    <div className='share-link-preview'>
                                        {privateShareLink}
                                    </div>
                                    <div className="copy-btn" style={{ backgroundColor: copyText === "Copy" ? 'var(--primary-color)' : 'var(--success-color)' }} onClick={() => { copyLinkToClipboard(privateShareLink) }}>
                                        {copyText}
                                    </div>
                                </div>
                            </div> : null
                        }







                    </div> : null
                }

            </div>

        </div>
    );
}

export default Header;
